<template>

    <div style="display:flex;justify-content:space-around;filter:invert(1);">
        <div v-if="imageGet('browser',useragent.browser.split(' ')[0])">
            <img style="width:1em;" 
                :src="require('@/assets/img/admin/browser/'+ useragent.browser.split(' ')[0] +'.svg')"
            />
        </div>
        <div v-else>
            {{useragent.browser.split(' ')[0]}}
        </div>

        <div v-if="imageGet('platform',useragent.platform.split(' ')[0])">
            <img style="width:1em;" 
                :src="require('@/assets/img/admin/platform/'+ useragent.platform.split(' ')[0] +'.svg')"
            />
        </div>

        <div v-else>
            {{useragent.platform.split(' ')[0]}}
        </div>

        <div>
            <img style="width:1em;" 
                :src="require('@/assets/img/admin/platform/'+ iconPlatform(useragent.isMobile,useragent.isTablet)+'.svg')"
            />
        </div>

    </div>

</template>

<script>


	export default {
		name: 'UserAgent',
        props: ['useragent'],
        data: function() {
            return {
            }
        },
        methods: {
            iconPlatform: function(isMobile,isTablet) {
                if (isMobile) {
                    if (isTablet) return "Tablet";
                    else return "Phone";
                } 
                else return "Desktop";
            },
            imageGet: function(type,data) {
                let aviableData = { 
                    platform: ['Android','Apple','app','Chrome OS','Desktop','iPad','iPhone','iPod','Kindle Fire','Linux','Microsoft','Phone','Tablet','unknown','web','Windows Phone'],                    
                    browser: ['Android Browser','AppleWebKit','Chrome','Edge','Firefox','Opera Next','Opera_Next','Opera','Safari','Samsung','Silk']
                };                
                if (aviableData[type].includes(data)) {
                    return true;
                }                    
                else{
                    return false;
                }
            },
        }

	}
</script>

<style scoped>

    .halloffame {
        position:absolute;
        width: 80%;           
        top:10%;
        left:10%;
        box-sizing: border-box;
        height: 80%;
        background-color:rgb(41, 20, 20);
    }

    .hallHeader {
        display: flex;
        justify-content: space-between;
        padding: 0.5em 1em;
    }

    .headerTitle {
        font-size:1.2em;
    }
    
    .headerContent {
        margin:1em;
    }


</style>
