<template>
    <div> 
        <div style="text-align:center;"> 
            <div class="statsPlayersControls">
                <div>Matches</div>
                <div> {{ matchesList.length }}</div>
                <div @click="opened=!opened"> {{ !opened ? "🔽" : "🔼"  }} </div>
            </div>
            <div class="filters" v-if="opened"> 
                <div> 
                        <input type="checkbox" v-model="filters.expand">Only Closed
                </div>
                <div>
                    <div> Type </div>
                    <select v-model="filters.type"> 
                        <option value="">All</option>
                        <option value="classic">Classic</option>
                        <option value="abak">Abak</option>
                        <option value="practice">Practice</option>
                    </select>
                </div>
                <div>
                    <div> Reason </div>
                    <select v-model="filters.reason"> 
                        <option value="">All</option>
                        <option value="server">Server</option>
                        <option value="open">Open</option>
                        <option value="playing">Playing</option>
                    </select>
                </div>
                <div>
                    <div> Opponent </div>
                    <select v-model="filters.opponentType"> 
                        <option value="">All</option>
                        <option value="CPU">CPU</option>
                        <option value="HUMAN">Human</option>
                    </select>
                </div>
                <div> 
                    <div> Player </div>
                    <input style='width:4em' maxlength=5 type="text" v-model="filters.player_id" />
                </div>
                <div @click="filterMatches()"> filter </div>                
            </div>
        </div>
        <div v-if="opened" class="statsPlayersTable"> 
            <DataTable class="display"> 
                <thead>
                    <tr style="text-align:center;"> 
                        <th>created</th>
                        <th>type</th>
                        <th>turns</th>
                        <th>reason</th>
                        <th>p1</th>
                        <th>p2</th>
                        <th>points</th>
                        <th>cube</th>
                        <th>goal</th>
                        <th>ids</th>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="t in matchesListFiltered"
                        :key="'tm'+t">
                        
                        <td> {{  t.gcreated ? 
                                    Math.round((new Date().getTime() - new Date(t.gcreated).getTime())/100/60/60)/10 : 
                                    Math.round((new Date().getTime() - new Date(t.icreated).getTime())/100/60/60)/10  
                             }}
                        </td> 

                        <td>
                            <img style='width:1em;' :src='"/img/symbols/type_"+t.mtype+".svg"' />
                        </td>

                        <td> {{ t.gturns  }}</td>

                        <td v-if="t.greason">
                            <img style="width:1.2em" :src="require('@/assets/img/actions/'+t.greason+'.svg')"/>
                        </td>
                        <td v-else> </td>

                        <td @click="$emit('request-player-info',t.p1id)">
                            {{ t.p1id }} <br>
                            <div :class="{ boxWinner : t.gwinner == 1 }">{{ t.p1name.slice(0,10) }}</div>
                            <img style="width:1em" :src="t.p1image" />
                            <img style="width:1.3em;" :src="'/img/players/abaker/'+t.p1abaker+'.svg'">
                            {{countryCodeEmoji(t.p1country)}}
                        </td>

                        <td v-if="t.p2id" :class="{ boxWinner : t.gwinner == 0 }" @click="$emit('request-player-info',t.p2id)">
                            {{  t.p2id }}
                            <div :class="{ boxWinner : t.gwinner == 0 }">{{ t.p2name.slice(0,10) }}</div>
                            <img style="width:1em" :src="t.p12mage" />
                            <img style="width:1.3em;" :src="'/img/players/abaker/'+t.p2abaker+'.svg'">
                            {{countryCodeEmoji(t.p2country)}}
                        </td>
                        <td v-else>
                            <div :class="{ boxWinner : t.gwinner == 0 }"> 
                                {{ t.opponentType }}
                                {{ t.opponentLevel }}
                            </div>
                        </td>

                        <td v-if="t.gid"> 
                            <div>{{t.gpoints}}</div>
                        </td>
                        <td v-else>  </td>

                        <td v-if="t.gid"> 
                            <div style="display:none">{{ t.gcubeValue }}</div>
                            <img style="width:1.2em" :src="require('@/assets/img/dados/dd'+t.gcubeValue+'.svg')"/>
                        </td>
                        <td v-else>  </td>

                        <td>
                            {{ t.goal }}
                        </td>   

                        <td > 
                            {{ t.mid  }}<br>
                            {{ t.gid  }}
                        </td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    </div>
</template>

<script>

    //import DateTimeDisplay from './DateTimeDisplay.vue';

    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net';
    DataTable.use(DataTablesLib);
    const { countryCodeEmoji} = require('country-code-emoji');

	export default {
		name: 'MatchesList',
        props: ['matchesList'],
        components: { DataTable },
        data: function() {
            return {
                opened: false,
                filters: {
                    game_selected:null,
                    type:null,
                    reason:null,
                    player_id:null,
                    opponentType:null,
                    expand:false,
                },
                matchesListFiltered:[]
            }
        },
        emits: ['request-player-info'],
        methods: {
            countryCodeEmoji,
            filterMatches: function() {
                console.log("filtering");
                let newFilter = [];
                for (let m in this.matchesList) {
                    let match = this.matchesList[m];
                    let finishedPlaying = match.greason && match.greason != "byserver";
                    let notFinished = match.greason == null;
                    let showfinishedPlaying = this.filters.reason == "playing" && finishedPlaying;
                    let shownotFinished = this.filters.reason == "open" && notFinished;
                    let showServerClosed = this.filters.reason == "server" && match.greason && match.greason == "byserver";
                    //let showOpponentType = match.opponentType && match.opponentType == this.filters.opponentType;
                    if ( 
                        ( !this.filters.type || match.mtype == this.filters.type ) && 
                        ( !this.filters.opponentType || match.opponentType == this.filters.opponentType ) && 
                        ( !this.filters.reason || showfinishedPlaying || shownotFinished || showServerClosed ) &&
                        ( !this.filters.player_id || match.p1id == this.filters.player_id || match.p2id == this.filters.player_id ) &&
                        ( !this.filters.expand || match.gid )
                        )

                        newFilter.push(match);
                }
                this.matchesListFiltered = newFilter;
                this.opened = false;
                setTimeout(()=> { this.opened = true}, 100);
            }
        },
        watch: {
            matchesList: function() {
                this.matchesListFiltered = this.matchesList;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                this.matchesListFiltered = this.matchesList;
            });
        }

	}
</script>

<style lang="scss" scoped>

    .statsPlayersTable {
            
            margin-top:0.5em;

            table {
                width: 100%;
                background:darken($c4,5%);
                border-radius: 0.5em;
            }

            thead {
                padding:0.2em;
                th {
                    border-bottom: 1px solid black;
                    padding: 0.3em;
                    margin: 0.3em;
                    background: none;
                }
            }
            td {
                padding:0.3em;
                text-align: center;
            }
            tbody tr:nth-child(even) {
                background:darken($c4,10%);
            }        
        }

        .detail {
            display: flex;
            justify-content: space-around;
            position:fixed;
            background:$c6;
            width:100%;
            height: 5em;
            padding:0.3em;
            >div {
                background:$c5;
                padding:1em;
            }
        }


        .todayGames {
        text-align: center;  
        width: 100%;      
    }

    .todayGame {
        background-color:$c6;
        margin:0.5em;        
        border-radius: 0.5em;
        >div {
            display:flex;
            align-items:center;
            justify-content: space-around;
        }
    }

    .matchInfo {
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-direction: column;
        >div {
            display:flex;            
        }

        >div:nth-child(2)>div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
        }
        >div:nth-child(3){
            >div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
            }
            :nth-child(1) {
                background: $c0;
            }
            :nth-child(2) {
                background: $checkerwhite;
            }
            :nth-child(3) {
                background: $checkerblack;
            }
        }
    }

    .dateContainer {
        text-align:center;
    }

    .detailHide {
        display:none;
    }

    .gameOverView1 {
        display:flex;
        justify-content: space-around;
        >div {
            margin:0.3em;
            background-color: $c8;
            padding:0.3em;
        }
        text-align: center;
    }

    .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

   .cpuContainer {
       background-color:$c11;
       border-radius: 0.5em;
       padding: 1em;

       >div:nth-child(2) {
           background-color: $c3;
           border-radius: 0.3em;
           color:black;
       }
   }

   .statsPlayersControls {
        background-color:#5a4f2b;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1em;
        margin-top:0.3em;
        :last-child {            
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    .filters {
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        box-sizing: border-box;
        flex-wrap: wrap;
        >div {
            background: $c8;
            padding: 0.3em;
        }
    }

    .boxWinner {
        background:#11AA1155;
        padding:0.3em;
    }


</style>
