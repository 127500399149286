<template>
    <div> 
        <div class="filters"> 
            <div> Preferences </div>
            <div @click="getPreferences()"> Fetch </div>
        </div>
        <div class="filters" v-if="filters.elements!=null"> 

            <button @click="uiState.mode='list'"> List </button>
            <button @click="uiState.mode='stats'"> Stats </button>
        </div>
        <div v-if="uiState.mode=='list'"> 
            <table> 
                <thead> 

                </thead>
                <tbody> 
                    <tr 
                        v-for="m,i in preferences.filtered" 
                        :key="'m'+i"
                        >
                        <td> {{ m.id }}</td>
                        <td> {{ m.name }}</td>
                        <td> <DateTimeDisplay :datetime="new Date(m.date_registered)"/></td>
                        <td> <DateTimeDisplay :datetime="new Date(m.lastlogin)"/></td>
                        <template v-if="JSON.parse(m.preferences) !== null">
                            <td v-if="filters.selected[0]==null"> {{ JSON.parse(m.preferences) }}</td>
                            <td v-if="filters.selected[0]!==null && filters.selected[1]==null "> {{ JSON.parse(m.preferences)[filters.selected[0]]  }}</td>
                            <td v-if="filters.selected[1]!==null"> {{ JSON.parse(m.preferences)[filters.selected[0]][filters.selected[1]]  }}</td>
                        </template>
                        <td v-else> 
                            -
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="uiState.mode=='stats'" class="statsContainer"> 
            <div v-for="key1,i in Object.keys(preferences.stats)" :key="i" class="level1Container"> 
                <div class="title">{{ key1 }}</div>
                <div> 
                    <div v-for="key2,j in Object.keys(preferences.stats[key1])" :key="j" class="level2Container"> 
                        <div class="title">{{ key2 }} </div>
                        <div> 
                            <div v-for="value,k in Object.keys(preferences.stats[key1][key2])" :key="k" class="valueContainer"> 
                                <div>{{ value }} </div>
                                <div>{{ preferences.stats[key1][key2][value] }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="uiState.loading"> loading </div>
    </div>
</template>

<script>

    import DateTimeDisplay from './DateTimeDisplay.vue';
    const { countryCodeEmoji} = require('country-code-emoji');
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

	export default {
		name: 'PlayersPreferences',
        props: ['adminRights'],
        components: { DateTimeDisplay },
        data: function() {
            return {
                uiState: {
                    loading: false,
                    mode:'stats'
                },
                preferences: {
                    raw: [],
                    filtered: [],
                    stats: {}
                },
                filters: {
                    selected:[null,null,null],
                    elements:null,                    
                }
            }
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            filterData: function() {
                let rawData = this.preferences.raw;
                this.preferences.filtered = rawData;
                let level1 = Object.keys(this.filters.elements);
                this.preferences.stats = {};
                for (let l1 in level1) {
                    let key1 = level1[l1];
                    if (key1!='aceleratorsTemp') {
                        let level2 = Object.keys(this.filters.elements[key1]);
                        for (let l2 in level2) {
                            let key2 = level2[l2];
                            if (!this.preferences.stats[key1]) this.preferences.stats[key1] = {};
                            if (!this.preferences.stats[key1][key2]) this.preferences.stats[key1][key2] = {};
                            console.log(key1,key2);
                            for (let r in rawData) {
                                let element = JSON.parse(rawData[r].preferences);
                                console.log(element);
                                if (element) {
                                    let value = element[key1][key2];
                                    if (!this.preferences.stats[key1][key2][value]) this.preferences.stats[key1][key2][value] = 0;
                                    this.preferences.stats[key1][key2][value]++;                            
                                }                            
                            }
                        }
                    }
                }
            },
            getPreferences: function() {
                this.uiState.loading = true;
                let data = {
                }
                this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/admin/player/preferences/`,data,this.getAuthHeader()).then((response) => {
                        this.uiState.loading = false;
                        this.preferences.raw = response.data;
                        for (let m in response.data) {
                            let pr = JSON.parse(response.data[m].preferences)
                            if (pr !== null) {
                                this.filters.elements = pr;
                                break;
                            }

                        }
                        this.filterData();
                    }
                    ).catch((error) => {
                        this.uiState.loading = false;
                        console.log(error);
                        }
                    );
            }
        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }

	}
</script>

<style lang="scss" scoped>

        table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        

        .detail {
            display: flex;
            justify-content: space-around;
            position:fixed;
            background:$c6;
            width:100%;
            height: 5em;
            padding:0.3em;
            >div {
                background:$c5;
                padding:1em;
            }
        }


        .todayGames {
        text-align: center;  
        width: 100%;      
    }

    .todayGame {
        background-color:$c6;
        margin:0.5em;        
        border-radius: 0.5em;
        >div {
            display:flex;
            align-items:center;
            justify-content: space-around;
        }
    }

    .matchInfo {
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-direction: column;
        >div {
            display:flex;            
        }

        >div:nth-child(2)>div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
        }
        >div:nth-child(3){
            >div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
            }
            :nth-child(1) {
                background: $c0;
            }
            :nth-child(2) {
                background: $checkerwhite;
            }
            :nth-child(3) {
                background: $checkerblack;
            }
        }
    }

    .dateContainer {
        text-align:center;
    }

    .detailHide {
        display:none;
    }

    .gameOverView1 {
        display:flex;
        justify-content: space-around;
        >div {
            margin:0.3em;
            background-color: $c8;
            padding:0.3em;
        }
        text-align: center;
    }

    .statsContainer {

        .level1Container {
            >div.title {
                background:$c9;
            }
            background:$c6;
            >div:last-child {
                display: flex;
                flex-wrap: wrap;
            }
            margin:0.5em;
        }

        .level2Container {
            margin:0.5em;
            background:$c5;
            >div:last-child {
                display: flex;
            }
        }

        .valueContainer {
            background:$c4;
            margin:0.2em;
            padding: 0.3em;
            >div:last-child {
                background:$c0;
                padding: 0.3em;
            }
        }

    }

    .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

   .cpuContainer {
       background-color:$c11;
       border-radius: 0.5em;
       padding: 1em;

       >div:nth-child(2) {
           background-color: $c3;
           border-radius: 0.3em;
           color:black;
       }
   }

   .statsPlayersControls {
        background-color:#5a4f2b;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1em;
        margin-top:0.3em;
        :last-child {            
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    .filters {
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        box-sizing: border-box;
        flex-wrap: wrap;
        >div {
            background: $c8;
            padding: 0.3em;
        }
    }

    .messageDelivery {
        >div {
            >div {
                padding:0.5em;
                flex-grow: 0.3;
            }
            >div:last-child {
                border-radius: 0.3em;
                background: red;
                >div {
                    display: flex;
                    justify-content: space-around;
                    >div:first-child {
                        background:red;
                    }
                }
            }
        }
    }


</style>
