<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="filters"> 
            Options:
            <div>
                <label for="ilines">Lines</label>
                <input id="ilines" type="text" size="3" v-model="filters.lines"/>
            </div>
            <div>
                <label for="itype">Type</label>
                <select id="itype" v-model="filters.type"> 
                    <option value="out"> out </option>
                    <option value="error"> error </option>
                </select>
            </div>
            <div>
                <label for="isystem">System</label>
                <select id="isystem" v-model="filters.system"> 
                    <option value="engine"> engine </option>
                    <option value="server"> server </option>
                </select>
            </div>
            <div>
                <button @click="getLogs()"> Fetch </button>
            </div>
        </div>
        <div class="tab" v-if="logs.filtered">
            <DataTable :data="logs.filtered"> 
                <thead>
                    <tr> 
                        <th>Date</th>
                        <th>Message</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net';    

    DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminLogs',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                logs: {
                    logs: null,
                    filtered: null
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },  
                    lines:100,
                    system:'engine',
                    type:'out'
                }
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getLogs: function() {
                    this.uiState.loading = true;
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/logs/`,{
                        l:this.filters.lines,
                        system:this.filters.system,
                        type:this.filters.type
                    },this.getAuthHeader()).then((response) => {
                        this.processLogs(response.data);                        
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );
            },
            processLogs: function(response) { 
                let logs = response.split("\n");
                var logData = [];
                for (let l in logs) {
                    let logLine = logs[l];
                    const datePattern = /\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3})\].*?\[.+?\](.+)/;
                    const match = logLine.match(datePattern);
                    if (match) {
                        logData.push([match[1],match[2]]);
                    }                  
                }
                this.logs.logs = logData.reverse();
                this.logs.filtered = this.logs.logs;
            },
            filterLogs: function() {

            }
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getLogs();
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss" scoped>

 .filters {
    display: flex;
    justify-content: space-around;
    background:#858b18;
    margin-bottom:0.5em;
    border-radius: 0.5em;
    padding:0.3em;
 }

 ::v-deep .dataTables_paginate {
        background: red;
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        align-items: center;

        >span {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        >:first-child , >:last-child {
            background:blue;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }

    ::v-deep .dataTables_filter {
        display: inline-block;
        float: right;
    }
    ::v-deep .dataTables_length {
        display: inline-block;

    }
    
    ::v-deep .dataTables_wrapper {
    }

    ::v-deep .dataTables_info {
        background-color: blue;
    }

</style>