<template>
    <div> 
        <div><NavigationMenu /></div> 

        <h2>Send Messages </h2>
        <div class="tab"> 
            <uiMessage :uiState="uiState" />
            <h3 style="background:green" v-if="!uiState.loading && response.affectedRows==5"> Message Created </h3>
            <div> 
                <h3> Identification </h3>
                <label for="msg_name">Name</label>
                <input style="width: 90%;" id="msg_name" type="text" v-model="message.name">
                <label for="msg_description">Description</label>
                <textarea style="width: 90%;" id="msg_description" v-model="message.description"></textarea>
            </div>

            <div> 
                <h3> Title </h3>
                <div v-for="ml in message.languages" :key="'txt_'+ml">
                    <label :for="'tx_title_'+ml">{{ ml }}</label>
                    <input style="width: 90%;" :id="'tx_title_'+ml" type="text" v-model="message.content.title[ml]">
                </div>
            </div>

            <div> 
                <h3> Description </h3>
                <div v-for="ml in message.languages" :key="'desc_'+ml">
                    <label :for="'tx_desc_'+ml">{{ ml }}</label>
                    <textarea style="width: 90%;" :id="'tx_desc_'+ml" v-model="message.content.text[ml]"></textarea>
                </div>
                <div>
                    <h3> Extras </h3>
                    <div>
                        <label for="ms_icon"> Image </label>
                        <select id="ms_icon" v-model="message.content.image"> 
                            <option v-for="i,k in images" :key="'mim_'+k">                                 
                                <p>{{i}}</p>
                            </option>
                        </select>
                        
                        <label for="ms_action_name"> Action Name </label>                        
                        <select id="ms_action_name" v-model="message.content.action.name"> 
                            <option v-for="i,k in actions" :key="'maction_'+k">                                 
                                <p>{{i}}</p>
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div> 
                <button @click="CreateMessage()">Create Message</button>
            </div>
        </div> 


        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import uiMessage from "@/components/admin/uiMessage.vue";
    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminSendMessages',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            uiMessage
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    },
                    filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    },

                },
                images: [
                    '','addfriend','brain','cake','calendar','chartup','combinatory','computer','connected',
                    'delete-clock','down','druida_atrapa','druida_come','faq','friendsgroup','goal',
                    'hall_icon','heart','help','karate','level','logopalestino','match','medal','nose',
                    'no','note','nrplayers','panel','payments','people','practice','register',
                    'removefriend','rules','rule','si','stake','star','start','stats','stickers',
                    'target','time','trophy','type_abak','type_classic','type_practice','up','versus',
                    'visibility','visitor','world'
                ],
                actions: [
                    '',
                    'changeMenu'
                ],
                action_sections: {
                    changeMenu: [
                        'board',
                        'uiOptions',
                        'acelerators',
                        'rules',
                        'p2p'
                    ]
                },
                message: {
                    languages: ['en','es'],
                    name:"New Message",
                    description:"Message Description",
                    content: {
                        title: {
                            en:"",
                            es:""
                        },
                        text: {
                            en:"",
                            es:""
                        },
                        image:"dsfsdfsd",
                        action:{
                            name:"changeMenu"
                        }
                    },
                    sender_id: 1,
                    selected_receiver_type:"personal",
                    /*
                    receivers: {
                        personal: {
                            id:{
                                label:"Player ID",
                                value:0
                            }
                        },
                        type:{
                            min_age: {
                                label:"Min Age",
                                value:0,
                            },
                            max_age:{
                                label:"Max Age",
                                value:0,
                            },
                            min_matches:{
                                label:"Min Matches",
                                value:0,
                            },
                            max_matches:{
                                label:"Max Matches",
                                value:0,
                            },
                            last_played:{
                                label:"Last Match",
                                value:0,
                            },
                        }
                    }
                    */
                },
                response: {}
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            CreateMessage: function() {
                    this.uiState.loading = true;
                    this.axios.post(`${WEBPROTOCOL}${this.uiState.filters.locations.current}:${APIPORT}/admin/messages/create/`,{
                        message: this.message
                    },this.getAuthHeader()).then((response) => {
                        this.response = response.data;
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );
            },            
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss" scoped>
    .tab {
        //border: 1px solid white;
        padding:0.5em;
        background-color: $c7;
        overflow:auto;
    }

</style>