<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab">
            <div style="display:flex;align-items: center;justify-content:space-around;flex-wrap:wrap" width="100%"> 
                <div style="display: flex;align-items: center;"> 
                    <p>Hours:</p>
                <input type='text' size='5' v-model="filters.historic.size"> 
                </div>
                <div style="display: flex;align-items: center;">
                    <p>Group (m):</p>
                    <select v-model="filters.historic.group">
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                    </select>            
                </div>
                <button @click="getUsageLog()">Reload </button>                
            </div>

            <uiMessage :uiState="uiState" />

            <div v-if="usagelog">  
                <Line
                    :chart-options="chartOptionsStacked"
                    :chart-data="usagelog"
                    :chart-id="'23rwer'"
                    :height="isVertical ? 400 : 150"
                />
            </div>
        </div>

        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import uiMessage from "@/components/admin/uiMessage.vue"

    //DataTable.use(DataTablesLib);   
    import { Line } from 'vue-chartjs'
    import {  Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from 'chart.js'
    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        PointElement,
        CategoryScale
    )

	export default {
		name: 'AdminOnlineHistoric',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            Line,
            uiMessage
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    historic: {
                            size:24,
                            group:15,
                        },
           
                },
                usagelog: null,
                chartOptionsStacked: {
                    responsive: true,
                    scales: {
                        y1: {
                            beginAtZero:true,                            
                            position:"left"
                        },
                        y2: {
                            beginAtZero:true,                            
                            position:"right"
                        },
                        
                    }
                },
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getUsageLog: function() {
                this.uiState.loading = true;
                this.uiState.tabShow='usagelog';
                this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/usagelog`,{l:this.filters.historic.size*60} ,this.getAuthHeader()).then((response) => {
                    this.usagelog = this.processUsageLog(response.data);
                    this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );                
            },
            processUsageLog: function(rawData) { 
                let r = {
                    labels: [],
                    datasets: [
                        { 
                            label:"game",
                            data:[],
                            backgroundColor: 'red',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'red',
                            pointHoverBorderColor: 'red',    
                            yAxisID:'y1'                        
                        },{ 
                            label:"lobby",
                            data:[],
                            backgroundColor: 'blue',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'blue',
                            pointHoverBorderColor: 'rgba(179,181,198,1)',                            
                            yAxisID:'y1'                        
                        },{ 
                            label:"visitor",
                            data:[],
                            backgroundColor: 'green',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'green',
                            pointHoverBorderColor: 'green',                            
                            yAxisID:'y1'                        
                        },{ 
                            label:"load",
                            data:[],
                            backgroundColor: 'yellow',
                            borderColor: 'yellow',
                            pointBackgroundColor: 'orange',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'orange',
                            pointHoverBorderColor: 'orange',                            
                            yAxisID:'y2'                        
                        }
                        ]
                }
                let tempdata = {};
                let data = rawData.split(/\n/);
                for (let i in data) {
                    let d = data[i].split(",");
                    //console.log(d);
                    if (d.length==5) {
                        let t = new Date(parseInt(d[0]));
                        let day = t.getDate();
                        let hour = t.getHours();
                        let timeRange = this.filters.historic.group;
                        let minuteRange = Math.floor(t.getMinutes()/timeRange)+1;
                        //console.log(t,day,hour,minuteRange);
                        if (!tempdata[`${day}-${hour}-${minuteRange}`]) tempdata[`${day}-${hour}-${minuteRange}`] = [[0,0,0,0],0];
                        tempdata[`${day}-${hour}-${minuteRange}`][1]++;
                        tempdata[`${day}-${hour}-${minuteRange}`][0][0]+=parseInt(d[1]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][1]+=parseInt(d[2]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][2]+=parseInt(d[3]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][3]+=parseFloat(d[4]);
                    }
                }

                let keys = Object.keys(tempdata);
                for (let k in keys ) {                    
                    r.labels.push(keys[k].split("-").slice(1).join(":"));
                    r.datasets[0].data.push(tempdata[keys[k]][0][0]/tempdata[keys[k]][1]);
                    r.datasets[1].data.push(tempdata[keys[k]][0][1]/tempdata[keys[k]][1]);
                    r.datasets[2].data.push(tempdata[keys[k]][0][2]/tempdata[keys[k]][1]);
                    r.datasets[3].data.push(tempdata[keys[k]][0][3]/tempdata[keys[k]][1]);
                }
                return r;
            },
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getUsageLog()
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">

.tab {
        //border: 1px solid white;
        padding:0.5em;
        background-color: $c7;
        overflow:auto;
        min-height: 20em;
    }


</style>