<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab"> 

            <div class='menu' style="display:flex;justify-content:space-around;"> 
                <div> 
                    Game Type
                    <select v-model="filters.player.mtype"> 
                        <option value="">all</option>
                        <option value="abak">abak</option>
                        <option value="practice">practice</option>
                        <option value="classic">classic</option>
                        <option value="a">played</option>
                    </select>
                </div>
                <div> 
                    Player Type
                    <select v-model="filters.player.ptype"> 
                        <option value="">all</option>
                        <option value="kGuest">kguest</option>
                        <option value="Guest">guest</option>
                    </select>
                </div>
                <div> 
                    Opponent
                    <select v-model="filters.player.opponent"> 
                        <option value="">all</option>
                        <option value="HUMAN">human</option>
                        <option value="CPU">cpu</option>
                    </select>
                </div>
                <div>Name <input type="text" style="width:4em;" v-model="filters.player.name"/> </div>
                <div>Min Games <input type="text" style="width:1em;" v-model="filters.player.mingames"/> </div>
                <div>Days <input type="text" style="width:2em;" v-model="filters.player.days"/> </div>
                <button @click="getNewPlayers()">Fetch</button>

            </div>
            
            <uiMessage :uiState="uiState" />

            <div class="playersTotals"> 
                <div> 
                    <div class="totalNew" v-if="newPlayersTotals.total">⅀{{newPlayersTotals.total}}</div>
                    <div class="notPlayed" v-if="newPlayersTotals.notcreated">🚫{{newPlayersTotals.notcreated}}</div>
                    <div class="onlyUnfinished" v-if="newPlayersTotals.unfinished">🏳️{{newPlayersTotals.unfinished}}</div>
                    <div class="hasFinished" v-if="newPlayersTotals.finished">🏁{{newPlayersTotals.finished}}</div>
                </div>
            </div>

            <div class="players">
                <div class="player" 
                    v-for="np in newPlayers" 
                    :key="'npl_'+np.id"  
                    :class="{ 
                        notPlayed: !np.games_number || np.games_number==0 ,
                        hasFinished: np.finishedgames

                    }"
                >

                    <div class="playername">
                        {{np.name}}&nbsp;
                        <span style="font-size:0.8em;">{{np.type == "kGuest" ? "⭐" : "❔" }}</span>
                        <span style="background-color:green;padding:0 0.2em;">{{np.id}}</span>
                        <button v-if="np.games_number && np.games_number>0" @click="redirectToUrl('/players/info?pid='+np.id)">➕</button>
                    </div>
                    
                    <div class="playerHead"   @click="uiState.playerBoxSelected != np.id ? uiState.playerBoxSelected = np.id : uiState.playerBoxSelected = 0;">
                        <div><img style="width:3em;" :src="np.image"> </div>
                        <div style="display:flex;flex-direction:column;align-items:center;">                        
                            <div style="text-align: center;">
                                <div v-if="np.country" > {{countryCodeEmoji(np.country)}}<br/>{{np.city}}</div>
                            </div>
                            <div>
                                {{formatedDateDiff(syncTime(Date.now()),np.date_registered)}}
                            </div>
                        </div>
                    </div>

                    
                    <div v-if="np.useragent">
                        <UserAgent :useragent="np.useragent" />                   
                    </div>
                    <div :class="{ detailHide: np.id != uiState.playerBoxSelected  }">
                        <div>{{np.useragent.ip }}</div>
                        <div>{{np.email}}</div>
                        
                    </div>

                </div>

            </div>

        </div>
        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import uiMessage from "@/components/admin/uiMessage.vue"

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminTemplate',
        props: [],
        components: {
            //DateTimeDisplay,
            UserAgent,
            NavigationMenu,
            uiMessage
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    playerType: '',     
                    player: {
                        id:null,
                        action:"games",
                        name:"",
                        mingames: 0,
                        days:1,
                        mtype:"",
                        ptype:"",
                        opponent:"",
                        date_registered: {
                            from: null,
                            to: null,
                        },
                        abaker:0,
                    },
                },
                newPlayers: [],
                newPlayersTotals: {},
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            countryCodeEmoji,
            syncTime: function(time) {
                return time;
            },  
            formatedDateDiff: function(dateEnd,dateStart,printUnit=true) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                if (typeof dateEnd != 'object') dateEnd = new Date(dateEnd);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${(timedif/60).toFixed(2)}${ printUnit ? ' m':"" }`;
                if (timedif<60*60*24) return `${(timedif/60/60).toFixed(1)}${ printUnit ? ' h':"" }`;
                return `${(timedif/60/60/24).toFixed(1)}${ printUnit ? ' d':"" }`;
            },
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getNewPlayers: function() {
                    this.uiState.loading = true;
                    let filter = {
                        days: this.filters.player.days,
                        mingames: this.filters.player.mingames,
                        name:this.filters.player.name,
                        abaker:this.filters.player.abake,
                        ptype:this.filters.player.ptype,
                        mtype:this.filters.player.mtype
                    }
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/matches/newplayers/`,
                    filter,this.getAuthHeader()).then((response) => {
                        this.processNewPlayers(response.data);
                        }).catch((error) => {
                            this.uiState.loading = false;
                            console.error(error);
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);

                            }
                        );
            },   
            processNewPlayers: function(players) {
                this.newPlayers = [];
                let newPlayersTotals = {
                        total:0,
                        notcreated:0,
                        unfinished:0,
                        finished:0
                    }
                for (let p in players) {
                    if (this.filters.playerType=='' || this.filters.playerType==players[p].type) {
                        let tp = players[p];
                        let ua = {};
                        try { ua = JSON.parse(tp.useragent); }
                        catch(error) { console.log(error); }
                        tp.useragent = ua;                        
                        if (!tp.games_number || tp.games_number == 0)
                            newPlayersTotals.notcreated++;
                        else {
                            if (tp.finishedgames) {
                                newPlayersTotals.finished++;
                            }
                            else {
                                newPlayersTotals.unfinished++;
                            }
                        }

                        this.newPlayers.push(tp);
                        newPlayersTotals.total = newPlayersTotals.notcreated + newPlayersTotals.finished + newPlayersTotals.unfinished; 
                        this.newPlayersTotals = newPlayersTotals;
                    }
                }
                this.uiState.loading = false;
            },
            redirectToUrl: function(url) {
                this.$router.push(url);
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">


.player {
        margin:0.5em;
        background-color: $c6;
        min-width: 7em;      
        border-radius: 0.5em;                  
        .playername {
            border-radius:0.5em 0.5em 0 0;
            background-color:$c8;
            text-align:center;
            padding:0.3em;            
        }
        >div:nth-child(2) {
            padding-top:0.5em;            
        }        
        >div:nth-child(4) {
            padding:1em;            
        }
    }

    .boxWinner {
        border:2px solid rgb(224, 194, 24);
    }

    .playerHead {
        display:flex;
        justify-content: space-around;
    }

    .players {
        display:flex;
        flex-wrap: wrap;
        margin:0;
    }

    .playersTotals {
        display: flex;
        margin-top:0.3em;
        >div:first-child {
            display: flex;
            flex-grow: 1;
            justify-content: space-around;
            >div {
                padding:0.2em;
                font-size:1.5em;
                border-radius: 0.5em;
            }
        }
        >div {
            padding:0.3em;
            border-radius: 0.5em;
        }

        >div:last-child {
            background:$c9;
        }

        .totalNew {
            background:black;
        }


    }
    

    .notPlayed {
            background:rgb(51, 6, 6) !important;
        }

    .hasFinished {
        background: rgb(7, 34, 7) !important;
        }

    .onlyUnfinished {
        background: rgb(53, 37, 11) !important;
        }
</style>