<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab">
            <div class="menu"> 
        
        <div :class="{ active: filters.usage.attribute=='games' }" @click="filters.usage.attribute='games';getUsage()">Games</div>
        <div :class="{ active: filters.usage.attribute=='players' }" @click="filters.usage.attribute='players';getUsage()">players</div>        
        <div :class="{ active: filters.usage.drange=='day' }" @click="filters.usage.drange='day';getUsage()">Day</div>
        <div :class="{ active: filters.usage.drange=='week' }" @click="filters.usage.drange='week';getUsage()">Week</div>
        <div :class="{ active: filters.usage.drange=='yearmonth' }" @click="filters.usage.drange='yearmonth';getUsage()">Month</div>
        <div> Time <input size="3" type='text' v-model='filters.usage.dateMax'/></div>
        <div> Player MinGames <input size="3" type='text' v-model='filters.usage.minGames'/></div>
        <div> Compare <input size="3" type='text' v-model='filters.usage.compare'/></div>
        </div>
            <div class="tab" v-if="!uiState.loading && usageData">
            <Line
                    :chart-options="chartOptions"
                    :chart-data="usageData"
                    :chart-id="'usage1'"
                    :height="isVertical ? 400 : 150"
                />
            </div>        
        </div>        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    

    //DataTable.use(DataTablesLib);   
    import { Line } from 'vue-chartjs'
    import {  Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from 'chart.js'
    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        PointElement,
        CategoryScale
    )

	export default {
		name: 'AdminGamesHistoric',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            Line,
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    usage: {
                        drange:'week',
                        dateMax:1000,
                        compare:1,
                        attribute:'games',
                        minGames:10,
                    },
                },
                usageData: null,
                chartOptions: {
                    responsive: true
                },
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getUsage: function() {
                    this.uiState.loading = true;
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/usage/`,{
                        range:this.filters.usage.drange,
                        dateMax:this.filters.usage.dateMax,
                        minGames:this.filters.usage.minGames
                    },this.getAuthHeader()).then((response) => {
                        this.usageData = this.processUsage(response.data);
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );
            },
            processUsage: function(usageData) {
                //this.usageData = usageData;
                let colors=['green','yellow','red','purple']
                let typeindex={
                    'abak':0,
                    'classic':1,
                    'practice': 2,
                    'total':3
                };
                let r = {
                    labels: [],
                    datasets: [
                        { 
                            label:'abak',
                            data:[],
                            backgroundColor: colors[0]
                        },
                        { 
                            label:'classic',
                            data:[],
                            backgroundColor: colors[1]
                        },
                        { 
                            label:'practice',
                            data:[],
                            backgroundColor: colors[2]
                        },
                        { 
                            label:'total',
                            data:[],
                            backgroundColor: colors[2]
                        }

                    ],

                }
                
                for (let k in usageData ) {  
                    if (!r.labels.includes(usageData[k].drange)) r.labels.push(usageData[k].drange);
                    r.datasets[3].data.push(0);
                }
                
                for (let k in r.labels ) {
                    let types = Object.keys(typeindex);
                    for (let t in types) {
                        let index = usageData.findIndex(item => item.drange === r.labels[k] && item.type === types[t]);
                        let  value = index !== -1 ? usageData[index][this.filters.usage.attribute] : 0; 
                        r.datasets[t].data.push(value);      
                        r.datasets[3].data[k]= r.datasets[3].data[k] + value;                      
                        }
                    } 
                    //r.datasets[currentPart][].data.push(usageData[k][this.filters.usage.attribute]);
                return r;
            },            
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getUsage()
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">

.tab {
        //border: 1px solid white;
        padding:0.5em;
        background-color: $c7;
        overflow:auto;
        min-height: 20em;
    }

.active {
    background:green !important;
}


</style>