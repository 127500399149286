<template>
    <div v-if="markData && markData.on">
        <svg
            style="position:absolute;filter:drop-shadow(2px 4px 6px black);left:0;top:0;" 
            :style="{ width: XunitSize*100 + 'px',height: YunitSize*100 + 'px', opacity: 1 }"
            class="apagarReset"
            >                    
            <!-- Curve -->
            <path :stroke="f.c" fill="transparent" :stroke-width="strokeWidth"
                :d="`M ${(f.x1*colWidth+(colWidth/2))*XunitSize} ${(f.y1*colHeight)*YunitSize} 
                            C ${(f.x1*colWidth+(colWidth/2))*XunitSize} ${(f.y1+f.o*(0.5+f.horchange*Math.abs(f.x1-f.x2)/3))*colHeight*YunitSize}  
                            ${(f.x2*colWidth+(colWidth/2))*XunitSize} ${(f.y2+f.o*(0.5+Math.abs(f.x1-f.x2)/3))*colHeight*YunitSize} 
                            ${(f.x2*colWidth+(colWidth/2))*XunitSize} ${(f.y2*colHeight)*YunitSize} `"/>                
            
            <!-- Triangle -->
            <polygon v-if="false" :points="` ${(f.x1*colWidth+(colWidth/2))*XunitSize-(XunitSize*3)} ${((f.y1)*colHeight)*YunitSize},
                                            ${(f.x1*colWidth+(colWidth/2))*XunitSize+(XunitSize*3)} ${((f.y1)*colHeight)*YunitSize},
                                            ${(f.x1*colWidth+(colWidth/2))*XunitSize} ${((f.y1)*colHeight)*YunitSize+-1*f.o*(XunitSize*3)}                                                    
                                            `" :stroke="f.c" :fill="f.c">
            </polygon>
            <!-- Triangle -->
            <polygon  :points="` ${(f.x2*colWidth+(colWidth/2))*XunitSize-(XunitSize*2)} ${((f.y2)*colHeight)*YunitSize},
                                            ${(f.x2*colWidth+(colWidth/2))*XunitSize+(XunitSize*2)} ${((f.y2)*colHeight)*YunitSize},
                                            ${(f.x2*colWidth+(colWidth/2))*XunitSize} ${((f.y2)*colHeight)*YunitSize+-1*f.o*(XunitSize*3)}                                                    
                                            `" :stroke="f.c" :fill="f.c" style="z-index:1000">
            </polygon>                          
            <circle 
                :cx="`${((f.x1+f.x2)*colWidth/2+(colWidth/2))*XunitSize}`" 
                :cy="f.verticalchange ? 
                    (f.y1*colHeight)*YunitSize - ( f.y1 > f.y2 ? 1 :-1 ) * YunitSize*40  : 
                    (f.y1*colHeight)*YunitSize - f.horchange * YunitSize * strokeWidth * Math.abs(f.x1-f.x2)/5" 
                :r="YunitSize*2" fill="#FFF" opacity="0.5" stroke="black" :stroke-width="YunitSize/2"/>
            <text 
                 :x="`${((f.x1+f.x2)*colWidth/2+(colWidth/2))*XunitSize}`" 
                 :y="f.verticalchange ? 
                    (f.y1*colHeight)*YunitSize - ( f.y1 > f.y2 ? 1 :-1 ) * YunitSize*40  : 
                    (f.y1*colHeight)*YunitSize - f.horchange * YunitSize * strokeWidth * Math.abs(f.x1-f.x2)/5"  
                 font-family="Arial" :font-size="YunitSize*3" text-anchor="middle" dominant-baseline="central">{{markData.index}}</text>

        </svg>  
   
    </div>        
</template>

<script>

    var posHelper = require('../../assets/js/positionHelpers');


	export default {
		name: 'MoveArrows',
        props: ['markData','colWidth',
                'XunitSize','YunitSize','maxTop','playerID','direction'],
        data: function() {
            return {
            }
        },
        components: { },
        computed: {        
            f: function() {
                return {
                    c: this.markData.color,
                    x1: this.translatex(this.markData.position[0]) ,
                    y1: this.translatey(this.markData.position[0]) ,
                    x2: this.translatex(this.markData.position[2]) ,
                    y2: this.translatey(this.markData.position[2]) ,
                    o: this.calcdirection(this.markData.position[2]),
                    horchange: this.calchorchange(this.markData.position[0],this.markData.position[2]),
                    verticalchange: (this.markData.position[0] > 12 && this.markData.position[2] <= 12) || (this.markData.position[0] <= 12 && this.markData.position[2] > 12)
                    
                }
            },             
            colHeight: function() {
                return this.colWidth;
            },
            strokeWidth: function() {
                return this.XunitSize;
            },                   
        },
        methods: {	
            x: posHelper.x,
            y: posHelper.y,
            translatex: function(x) {
                let rx = 0;
                if (x>12) {
                    rx = x -12
                    if (x>18) {
                        rx++;
                    }
                }
                else {
                    rx = 13 - x;
                    if (x<7) rx++;
                }
                if (this.direction==1)
                    return rx;
                else {
                    return 14-rx;
                }
            },
            translatey: function(y) {
                let ry = y>12 ? 3: 14;
                return ry;
            },
            calcdirection: function(column) {
                return column>12 ? 1 : -1; 
            },
            calchorchange: function(c1,c2) {
                if (c1>12 && c2<=12 || c2>12 && c1<=12  ) return -1;
                return 1;
            }
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    div {
        position:absolute;
        pointer-events: none;
    }
    img {
        position: absolute;
    }

</style>
