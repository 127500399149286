<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
<!-- *********************************************************************************** -->    
<!-- ************************PLAYER INFO **************************************************** -->    
<!-- *********************************************************************************** -->    

    <div v-if="uiState.loading"> Loading </div>
    <div class="tab">        
        <div class="statusItems">
            <div><div>id</div><input id="playerid" size="3" type="text" v-model="filters.player.id" /></div>
            <div><div>days</div><input id= "Time" size="2" type="text" v-model="filters.maxdays" /></div>
            <div> 
                <div>
                    <div>Type</div>
                    <select v-model="filters.player.action" @change="playerInfo=null">
                        <option value="games">Games</option>
                        <option value="statsday">Stats</option>
                    </select>
                </div>
            </div>
            <div>
                <div>Action</div>
                <div>
                    <input type="button" @click="getPlayerInfo()" value="Fetch"/>
                </div>
            </div>
        </div>
        <div>
            <div 
                v-if="playerInfo && playerInfo.length>0 && playerInfo[0].useragent" class="playerInfoContainer"
                > 
                <div>
                    <div style="display:flex;"> 
                        <div>{{playerInfo[0].name}}</div>
                        <div><img style="width:1.5em;" :src="playerInfo[0].image"/></div>
                    </div>
                    <div style="display:flex;"> 
                        <div>{{countryCodeEmoji(playerInfo[0].country)}}</div>
                        <div> {{playerInfo[0].city}}</div>
                    </div>
                    <div> {{playerInfo[0].logincount}}</div>
                </div>
                <UserAgent v-if="playerInfo[0].useragent" :useragent="JSON.parse(playerInfo[0].useragent)" />                   
                <div>
                    <DateTime :date="playerInfo[0].date_registered" :show="{ date:true, hour:true }" />
                </div>
            </div>
            <div v-if="filters.player.action=='statsday' && playerInfo" class="playerStats"> 
                <div  class="dayStatList">
                    <table> 
                        <thead> 
                            <tr> 
                                <td>date</td>
                                <td>g#</td>
                                <td>m#</td>
                                <td>l3</td>
                                <td>l4</td>
                                <td>l5</td>
                                <td>l6</td>
                                <td>p2p</td>
                                <td>type</td>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr v-for="pl in playerInfo" :key="'pl'+pl">
                                <td><DateTimeDisplay :datetime="new Date(pl.date)" :showtime="false"/></td>
                                <td>{{pl.games_number}}</td>
                                <td>{{pl.matches_number}}</td>
                                <td>{{pl.matchesl3}}</td>
                                <td>{{pl.matchesl4}}</td>
                                <td>{{pl.matchesl5}}</td>
                                <td>{{pl.matchesl6}}</td>
                                <td>{{pl.matchesp2p}}</td>
                                <td>
                                    <img style="width:1.3em;" :src="'/img/symbols/type_'+pl.type+'.svg'" />
                                </td>
                            </tr>
                        </tbody>                        
                    </table>
                </div>
            </div>            
            <div v-if="filters.player.action=='games'" class="playerStatsGames" > 
                <div class="gamesList">
                    <table> 
                        <thead>
                            <tr> 
                                <td>Date</td>
                                <td>O</td>
                                <td>Ty</td>
                                <td>Lv</td>
                                <td>Cb</td>
                                <td>Tr</td>
                                <td>Pts</td>
                                <td>Rsn</td>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr 
                                v-for="pl,i in playerInfo" 
                                :key="'pl'+i" 
                                >
                                <td style="font-size:0.6em"> 
                                    <DateTimeDisplay :datetime="new Date(pl.created_at)" />
                                </td>
                                <td :class="{ cwinner: (
                                    pl.winner == 0 &&  pl.player1_id == pl.player_id) || 
                                    ( pl.winner == 1 && pl.player2_id == pl.player_id ) , 
                                    clooser: (
                                    pl.winner == 1 &&  pl.player1_id == pl.player_id) || 
                                    ( pl.winner == 0 && pl.player2_id == pl.player_id )                             
                                    }">
                                    <img v-if="pl.opponentType=='CPU'" style="width:1.3em;" :src="'/img/symbols/computer.svg'" />
                                    <img v-if="pl.opponentType=='HUMAN'" style="width:1.3em;" :src="'/img/symbols/person.svg'" />
                                </td>
                                <td>
                                    <img style="width:1.3em;" :src="'/img/symbols/type_'+pl.gameType+'.svg'" />
                                </td>
                                <td v-if="pl.opponentType == 'CPU'">
                                    <img style="width:1.3em;" :src="'/img/players/cpu/'+pl.opponentLevel+'.svg'" />
                                </td>
                                <td v-else>
                                    {{ pl.player2_id }}
                                </td>
                                <td><img style="width:1.3em;" :src="'/img/dados/dd'+pl.cubevalue+'.svg'" /></td>
                                <td>{{pl.turns}}</td>
                                <td>{{pl.points}}</td>
                                <td><img style="width:1em;" :src="reasonSrc(pl.reason)" /></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>            
        </div>
    </div>    

        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";    
    import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    import DateTime from "@/components/admin/DateTime.vue";
    const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminPlayerInfo',
        props: [],
        components: {
            DateTimeDisplay,       
            DateTime,     
            UserAgent,
            NavigationMenu,
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                playerInfo:null,
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    player:{
                        id:null,
                        action:'statsday',                        
                    },                  
                    maxdays:100     
                }
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            reasonSrc: function(reason) {
                if (typeof reason == 'undefined') return false;
                return require(`@/assets/img/actions/${reason}.svg`);
            },
            getPlayerInfo: function() {
                    this.playerInfo = null;
                    this.uiState.loading = true;
                    if (!this.filters.player.id) {
                        console.log("no id");
                        return;
                    }
                    const qryparams = { player : this.filters.player , maxdays:this.filters.maxdays }
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/player/info/`, qryparams ,this.getAuthHeader() ).then((response) => {
                        console.log(response.data);
                        this.uiState.loading = false;
                        this.playerInfo = response.data;
                        }).catch((error) => {
                            this.uiState.loading = false;
                            console.error(error);
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            }
                        );
            },
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.filters.player.id = this.$route.query.pid || null;
                if (this.filters.player.id) {
                    this.getPlayerInfo();
                }

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">

    .statusItems {
            display:flex;
            >div:nth-child(1) {
                background-color:$c8;
            }
            >div {
                background-color:$c5;
                padding:0.3em;
                border-radius: 0.3em;
                margin:0.2em;
            }
            span {
                background-color:$c3;
                padding: 0.05em 0.5em;
                font-size:0.8em;
            }
        }

    .playerInfoContainer {
            display:flex;
            background-color:rgb(3 41 8);
            justify-content: space-around;
            margin: 0.3em;
            padding: 0.3em;
            border-radius: 0.3em;
            align-items: center;            
    }

</style>