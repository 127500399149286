<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab">
            <div style="display: flex;"> 
                <uiMessage :uiState="uiState" />
                <button @click="getCurrentMatches(filters.onlyactive)"> ⟲ </button>
            </div>
            <h3>CPU</h3>

            <div class="gamesContainer">
                <div 
                    v-for="g in currentMatches.cpu"
                    :key="'hg'+g.matchID"
                    class="gameContainer"
                >         
                    <div class="typeContainer"> 
                        <div> {{g.setupData.type}} </div>
                        <div v-if="g.setupData.type=='practice'"> {{calculatePracticeLevel(g.setupData.set)}} </div>
                        <div>{{g.setupData.opponent.level}} </div>
                    </div>
                    <div style="padding:0 0.5em;text-align:center;"> {{ g.matchID }}</div>
                    <div class="playerContainer" v-if="g.players[0].data">
                        <div style="display:flex;align-items:center;"> 
                            <div>{{g.players[0].data.id}}</div>
                            <div style="margin-right:0.2em;">{{g.players[0].data.name}}</div>
                            <div class="connected" :class="{ notConnected : !g.players[0].isConnected}">&nbsp;</div>
                        </div>
                        <div>{{formatedDateDiff(syncTime(Date.now()),g.players[0].data.date_registered)}}</div>
                        <div style="display: flex;align-items: center;">
                            <img :src="g.players[0].data.image">
                            <div>
                                <div v-if="g.players[0].data.country" > 
                                    {{countryCodeEmoji(g.players[0].data.country)}}
                                    <br/>{{g.players[0].data.city}}
                                </div>
                            </div>
                        </div>
                        <UserAgent v-if="g.players[0].data.useragent" :useragent="JSON.parse(g.players[0].data.useragent)" />                   

                    </div>
                    <div class="datesContainer"> 
                        <div>C:{{formatedDateDiff(syncTime(Date.now()),g.createdAt)}}</div> 
                        <div>U:{{formatedDateDiff(syncTime(Date.now()),g.updatedAt)}}</div>                  
                        <div>D:{{formatedDateDiff(g.updatedAt,g.createdAt)}}</div>                  
                    </div>
                </div>
            </div>  

            <h3>Humans</h3>

            <div class="gamesContainer">
                <div 
                    class="gameContainer twoPlayers"
                    v-for="g in currentMatches.human"
                    :key="'hg'+g.matchID"
                >
                    <div class="typeContainer"> 
                        <div> {{g.setupData.type}} </div>
                        <div>{{g.setupData.opponent.level}} </div>
                    </div>
                    <div style="padding:0 0.5em;text-align:center;"> {{ g.matchID }}</div>
                    <div style="display:flex;align-items:center;" v-if="g.players[0].data">
                        <div class="playerContainer">
                            <div style="display:flex;align-items:center;"> 
                                <div style="margin-right:0.2em;">{{g.players[0].data.name}}</div>
                                <div class="connected" :class="{ notConnected : !g.players[0].isConnected}">&nbsp;</div>
                            </div>
                            <img :src="g.players[0].data.image">
                        </div>
                        <div v-if="g.players[1].data" class="playerContainer">
                            <div style="display:flex;align-items:center;"> 
                                <div style="margin-right:0.2em;">{{g.players[1].data.name}}</div>
                                <div class="connected" :class="{ notConnected : !g.players[1].isConnected}">&nbsp;</div>
                            </div>
                            <img :src="g.players[1].data.image">
                        </div>
                    </div>
                    <div class="datesContainer"> 
                        <div>{{formatedDateDiff(syncTime(Date.now()),g.createdAt)}}</div> 
                        <div>{{formatedDateDiff(syncTime(Date.now()),g.updatedAt)}}</div>                  
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue";
    const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import uiMessage from "@/components/admin/uiMessage.vue"

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminGamesCurrent',
        props: [],
        components: {
            //DateTimeDisplay,
            UserAgent,
            NavigationMenu,
            uiMessage
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                players: {
                    list: [1,2,3,4]
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    onlyactive: parseInt(this.$route.query.active)
                },
                currentMatches: {
                    cpu:[],
                    human:[]
                }
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getCurrentMatches: function(activesOnly) {                                        
                    this.uiState.loading = true;
                    this.uiState.tabShow = "current";
                    this.axios.get(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/matches/current/`,this.getAuthHeader()).then((response) => {

                        if (response.data.error) {
                            this.uiState.loading = false;
                            console.error(response.data);
                            this.uiState.error = { on:true, type : response.data.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }

                        this.serverTimeDiff = Date.now() - response.data.now;
                        console.log(response.data);
                        this.processCurrentMatches(response.data.matches,activesOnly);
                        this.uiState.loading = false;
                        }).catch((error) => {
                            this.uiState.loading = false;
                            console.error(error);
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            }
                        );
            },
            processCurrentMatches: function(gameList,activeOnly = true) {
                this.currentMatches.cpu = [];
                this.currentMatches.human = [];
                for (let g in gameList) {
                    console.log(gameList[g]);
                    if (gameList[g].setupData.opponent.type=='CPU') {
                        if (gameList[g].players[0].isConnected || !activeOnly)
                            this.currentMatches.cpu.push(gameList[g]);
                    }                        
                    else 
                        if ((gameList[g].players[0].isConnected && gameList[g].players[1].isConnected) || !activeOnly)
                            this.currentMatches.human.push(gameList[g]);
                }
                this.currentMatches.cpu.sort( (a,b) => (a.updatedAt<b.updatedAt) ? 1: -1 );
                this.currentMatches.human.sort( (a,b) => (a.updatedAt<b.updatedAt) ? 1: -1 );
            },
            calculatePracticeLevel: function(set)  {
                let sum = 0;
                for (let s in set) {
                    sum+=set[s];
                }
                return sum-2;
            },
            formatedDateDiff: function(dateEnd,dateStart,printUnit=true) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                if (typeof dateEnd != 'object') dateEnd = new Date(dateEnd);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${(timedif/60).toFixed(2)}${ printUnit ? ' m':"" }`;
                if (timedif<60*60*24) return `${(timedif/60/60).toFixed(1)}${ printUnit ? ' h':"" }`;
                return `${(timedif/60/60/24).toFixed(1)}${ printUnit ? ' d':"" }`;
            },
            syncTime: function(time) {
                return time;
            },  


        },
        watch: {
            '$route.query': {
            handler(newQuery,oldQuery) {                
                if (oldQuery && newQuery!=oldQuery) {
                    window.location.reload();
                    }
                }
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getCurrentMatches(this.filters.onlyactive);
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style  lang="scss">

.gamesContainer {
        display: flex;
        flex-wrap: wrap;        
    }

   .gameContainer {
       background: $c9;
       display: flex;
       flex-direction: column;
       margin:0.2em;
       border-radius: 0.5em;

   }

   .typeContainer {       
       display:flex;
       justify-content: space-around;
       >div {
           margin: 0.2em 0.2em;
           background: black;
           padding:0.2em 0.5em;           
           border-radius: 0.3em;
       }
   }

   .datesContainer {
       display:flex;
       justify-content: space-around;
       >div {
           margin: 0.2em 0.2em;
           background: black;
           padding:0.2em 0.5em;           
           border-radius: 0.3em;
       }
   }

   .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

     .connected {
         background:green;
         width: 1em;
         display: flex;
         border-radius: 0.5em;
         height: 1em;
     }
   .notConnected {
       background: red;
   }


</style>