<template>
    <div> 
        <div><NavigationMenu /></div> 


        <uiMessage :uiState="uiState" />
        <div class="tab">
            <div style="display: flex;align-items: center;"> 
                <button @click="getUsageLog();"> ⟲ </button>
                <div style="width:100%">CPU</div>
                <div class="gamesTotal"> {{ currentMatches.cpu.length }}</div>
            </div>

            <div class="gamesContainer">
                <div 
                    v-for="g in currentMatches.cpu"
                    :key="'hg'+g.matchID"
                    class="gameContainer"
                    :class="{ complete: isVertical, half:!isVertical  }"
                    >         
                    <div class="typeContainer"> 
                        <div style="display: flex;align-items: center;"><img style='height:1em' :src="'/img/symbols/type_'+g.setupData.type+'.svg'">{{g.setupData.opponent.level}}</div>
                        <div v-if="g.setupData.type=='practice'"> {{calculatePracticeLevel(g.setupData.set)}} </div>                        
                        <div>C:{{formatedDateDiff(syncTime(Date.now()),g.createdAt)}}</div> 
                        <div>U:{{formatedDateDiff(syncTime(Date.now()),g.updatedAt)}}</div>                  
                        <div>D:{{formatedDateDiff(g.updatedAt,g.createdAt)}}</div>                  
                    </div>                    
                    <div class="playerContainer" v-if="g.players[0].data">
                        <div style="display:flex;align-items:center;width: 100%;justify-content: space-around;"> 
                            <div style="display: flex;align-items: center;">
                                <div class="connected" :class="{ notConnected : !g.players[0].isConnected}">&nbsp;</div>
                                <img :src="g.players[0].data.image">
                                <div style="margin-right:0.2em;">{{g.players[0].data.name}}</div>                            
                            </div>
                            <div>{{formatedDateDiff(syncTime(Date.now()),g.players[0].data.date_registered)}}</div>
                            <div v-if="g.players[0].data.country" @click="uiState.showCity=!uiState.showCity"> 
                                {{countryCodeEmoji(g.players[0].data.country)}}
                                <div v-if="uiState.showCity">{{g.players[0].data.city}}</div>
                            </div>
                            <UserAgent v-if="g.players[0].data.useragent" :useragent="JSON.parse(g.players[0].data.useragent)" />                   
                        </div>

                    </div>
                    <div class="datesContainer"> 
                    </div>
                </div>
            </div>  

            <div style="display: flex;align-items: center;border-top:1px solid white;margin-top:0.5em;"> 
                <div style="width:100%;margin-top:0.5em;">Humans</div>
                <div style="margin-top:0.5em;" class="gamesTotal"> {{ currentMatches.human.length }}</div>
            </div>

            <div class="gamesContainer">
                <div 
                    class="gameContainer twoPlayers"
                    v-for="g in currentMatches.human"
                    :key="'hg'+g.matchID"
                >
                    <div class="typeContainer"> 
                        <div> {{g.setupData.type}} </div>
                        <div>{{g.setupData.opponent.level}} </div>
                    </div>
                    <div style="padding:0 0.5em;text-align:center;"> {{ g.matchID }}</div>
                    <div style="display:flex;align-items:center;" v-if="g.players[0].data">
                        <div class="playerContainer">
                            <img :src="g.players[0].data.image">
                            <div style="display:flex;align-items:center;"> 
                                <div style="margin-right:0.2em;">{{g.players[0].data.name}}</div>
                                <div class="connected" :class="{ notConnected : !g.players[0].isConnected}">&nbsp;</div>
                            </div>
                        </div>
                        <div v-if="g.players[1].data" class="playerContainer">
                            <img :src="g.players[1].data.image">
                            <div style="display:flex;align-items:center;"> 
                                <div style="margin-right:0.2em;">{{g.players[1].data.name}}</div>
                                <div class="connected" :class="{ notConnected : !g.players[1].isConnected}">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div class="datesContainer"> 
                        <div>{{formatedDateDiff(syncTime(Date.now()),g.createdAt)}}</div> 
                        <div>{{formatedDateDiff(syncTime(Date.now()),g.updatedAt)}}</div>                  
                    </div>
                </div>
            </div>  

        </div>


        <div class="tab">
            <!--div style="display:flex;align-items: center;justify-content:space-around;flex-wrap:wrap" width="100%"> 
                <div style="display: flex;align-items: center;"> 
                    <p>Hours:</p>
                <input type='text' size='5' v-model="filters.historic.size"> 
                </div>
                <div style="display: flex;align-items: center;">
                    <p>Group (m):</p>
                    <select v-model="filters.historic.group">
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                    </select>            
                </div>
                <button @click="getUsageLog()">Reload </button>                
            </div -->

            <div v-if="usagelog">  
                <Line
                    :chart-options="chartOptionsStacked"
                    :chart-data="usagelog"
                    :chart-id="'23rwer'"
                    :height="isVertical ? 400 : 150"
                />
            </div>
        </div>
        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import uiMessage from "@/components/admin/uiMessage.vue"

    //DataTable.use(DataTablesLib);   
    import { Line } from 'vue-chartjs'
    import {  Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from 'chart.js'
    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        PointElement,
        CategoryScale
    )

	export default {
		name: 'AdminOnlineCombined',
        props: [],
        components: {
            //DateTimeDisplay,
            UserAgent,
            NavigationMenu,
            Line,
            uiMessage
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    },
                    showCity:false
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    historic: {
                            size:2,
                            group:5,
                        },
                    onlyactive:true,
                },
                usagelog: null,
                currentMatches: {
                    cpu:[],
                    human:[]
                },
                chartOptionsStacked: {
                    responsive: true,
                    scales: {
                        y1: {
                            beginAtZero:true,                            
                            position:"left"
                        },
                        y2: {
                            beginAtZero:true,                            
                            position:"right"
                        },
                        
                    }
                },
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getUsageLog: function() {
                this.getCurrentMatches(this.filters.onlyactive);
                this.uiState.loading = true;
                this.uiState.tabShow='usagelog';
                this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/usagelog`,{l:this.filters.historic.size*60} ,this.getAuthHeader()).then((response) => {
                    this.usagelog = this.processUsageLog(response.data);
                    this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );                
            },
            processUsageLog: function(rawData) { 
                let r = {
                    labels: [],
                    datasets: [
                        { 
                            label:"game",
                            data:[],
                            backgroundColor: 'red',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'red',
                            pointHoverBorderColor: 'red',    
                            yAxisID:'y1'                        
                        },{ 
                            label:"lobby",
                            data:[],
                            backgroundColor: 'blue',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'blue',
                            pointHoverBorderColor: 'rgba(179,181,198,1)',                            
                            yAxisID:'y1'                        
                        },{ 
                            label:"visitor",
                            data:[],
                            backgroundColor: 'green',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'green',
                            pointHoverBorderColor: 'green',                            
                            yAxisID:'y1'                        
                        },{ 
                            label:"load",
                            data:[],
                            backgroundColor: 'yellow',
                            borderColor: 'yellow',
                            pointBackgroundColor: 'orange',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'orange',
                            pointHoverBorderColor: 'orange',                            
                            yAxisID:'y2'                        
                        }
                        ]
                }
                let tempdata = {};
                let data = rawData.split(/\n/);
                for (let i in data) {
                    let d = data[i].split(",");
                    //console.log(d);
                    if (d.length==5) {
                        let t = new Date(parseInt(d[0]));
                        let day = t.getDate();
                        let hour = t.getHours();
                        let timeRange = this.filters.historic.group;
                        let minuteRange = Math.floor(t.getMinutes()/timeRange)+1;
                        //console.log(t,day,hour,minuteRange);
                        if (!tempdata[`${day}-${hour}-${minuteRange}`]) tempdata[`${day}-${hour}-${minuteRange}`] = [[0,0,0,0],0];
                        tempdata[`${day}-${hour}-${minuteRange}`][1]++;
                        tempdata[`${day}-${hour}-${minuteRange}`][0][0]+=parseInt(d[1]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][1]+=parseInt(d[2]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][2]+=parseInt(d[3]);
                        tempdata[`${day}-${hour}-${minuteRange}`][0][3]+=parseFloat(d[4]);
                    }
                }

                let keys = Object.keys(tempdata);
                for (let k in keys ) {                    
                    r.labels.push(keys[k].split("-").slice(1).join(":"));
                    r.datasets[0].data.push(tempdata[keys[k]][0][0]/tempdata[keys[k]][1]);
                    r.datasets[1].data.push(tempdata[keys[k]][0][1]/tempdata[keys[k]][1]);
                    r.datasets[2].data.push(tempdata[keys[k]][0][2]/tempdata[keys[k]][1]);
                    r.datasets[3].data.push(tempdata[keys[k]][0][3]/tempdata[keys[k]][1]);
                }
                return r;
            },
            getCurrentMatches: function(activesOnly) {                                        
                    this.uiState.loading = true;
                    this.uiState.tabShow = "current";
                    this.axios.get(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/matches/current/`,this.getAuthHeader()).then((response) => {

                        if (response.data.error) {
                            this.uiState.loading = false;
                            console.error(response.data);
                            this.uiState.error = { on:true, type : response.data.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }

                        this.serverTimeDiff = Date.now() - response.data.now;
                        console.log(response.data);
                        this.processCurrentMatches(response.data.matches,activesOnly);
                        this.uiState.loading = false;
                        }).catch((error) => {
                            this.uiState.loading = false;
                            console.error(error);
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            }
                        );
            },
            processCurrentMatches: function(gameList,activeOnly = true) {
                this.currentMatches.cpu = [];
                this.currentMatches.human = [];
                for (let g in gameList) {
                    let updatedDiff = (new Date() - gameList[g].updatedAt)/1000;
                    //console.log(updatedDiff);
                    //console.log(gameList[g]);
                    if (gameList[g].setupData.opponent.type=='CPU') {
                        if (updatedDiff<45 && (gameList[g].players[0].isConnected || !activeOnly))
                            this.currentMatches.cpu.push(gameList[g]);
                    }                        
                    else 
                        if ((gameList[g].players[0].isConnected && gameList[g].players[1].isConnected) || !activeOnly)
                            this.currentMatches.human.push(gameList[g]);
                }
                this.currentMatches.cpu.sort( (a,b) => (a.updatedAt<b.updatedAt) ? 1: -1 );
                this.currentMatches.human.sort( (a,b) => (a.updatedAt<b.updatedAt) ? 1: -1 );
            },
            calculatePracticeLevel: function(set)  {
                let sum = 0;
                for (let s in set) {
                    sum+=set[s];
                }
                return sum-2;
            },
            formatedDateDiff: function(dateEnd,dateStart,printUnit=true) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                if (typeof dateEnd != 'object') dateEnd = new Date(dateEnd);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${(timedif/60).toFixed(2)}${ printUnit ? ' m':"" }`;
                if (timedif<60*60*24) return `${(timedif/60/60).toFixed(1)}${ printUnit ? ' h':"" }`;
                return `${(timedif/60/60/24).toFixed(1)}${ printUnit ? ' d':"" }`;
            },
            syncTime: function(time) {
                return time;
            },  
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getUsageLog();                
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss" scoped>

.tab {
        //border: 1px solid white;
        margin-bottom: 1em;
        padding:0.5em;
        background-color: #202020;
        overflow:auto;
        min-height: 5em;
        border-radius: 0.5em;
    }


    .playerContainer {
       display:flex;
       flex-direction: column;
       width: 100%;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0 0 0.5em 0.5em;
       padding:0;
       margin:0;
   }

   .cpuContainer {
       background-color:$c11;
       border-radius: 0.5em;
       padding: 1em;

       >div:nth-child(2) {
           background-color: $c3;
           border-radius: 0.3em;
           color:black;
       }
   }

   .gamesTotal {
    padding: 0.2em 0.5em;
    background:red;
    border-radius: 0.5em;
    margin-right: 0.5em;
   }

    .complete {
        width: 100%;
    }

    .half {
        width: 30%;
    }

</style>