<template>
    <div> 
        <div><NavigationMenu /></div> 
        <div class="filtersBar"> 
            <div>
                <label for="listName">Name</label>
                <input size="7" id="listName" type="text" v-model="uiState.filters.list.name">
                <label for="listEmail">Email</label>
                <input size="7" id="listEmail" type="text" v-model="uiState.filters.list.email">
            </div>
            <div> 
                <label for="listAbakerMin">Abaker Min</label>
                <input size="1" id="listAbakerMin" type="text" v-model="uiState.filters.list.abaker_min">
                <label for="listAbakerMax">Abaker Max</label>
                <input size="1" id="listAbakerMax" type="text" v-model="uiState.filters.list.abaker_max">
            </div>
            <div> 
                <label for="listDaysMin">Days Min</label>
                <input size="2" id="listDaysMin" type="text" v-model="uiState.filters.list.days_min">
                <label for="listDaysMax">Days Max</label>
                <input size="4" id="listDaysMax" type="text" v-model="uiState.filters.list.days_max">
            </div>
            <div> 
                <label for="listCountry">Country</label>
                <input size="2" id="listCountry" type="text" v-model="uiState.filters.list.country">
            </div>
            <button @click="getPlayerList"> Fetch </button>
        </div>

        <DataTable class="resultsTable" :data="players.list"> 
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Country</th>
                    <th>Registered</th>
                    <th>Abaker</th>
                </tr>
            </thead>
        </DataTable>
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');

    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    const { countryCodeEmoji} = require('country-code-emoji');
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net';    

    DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminPlayers',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    },
                    filters: {
                        locations: {
                                current: EDGELOCATIONS['west'],
                                available: EDGELOCATIONS
                            },    
                        list: {
                            name:"",
                            email:"",
                            abaker_max:10,
                            abaker_min:0,
                            days_min:0,
                            days_max:100000,
                            country:""
                        }                
                    }
                },
                players: {
                    list: []
                },
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            countryCodeEmoji,
            getPlayerList: function() {
                    this.uiState.loading = true;
                    this.axios.post(`${WEBPROTOCOL}${this.uiState.filters.locations.current}:${APIPORT}/admin/player/list`,{
                        name:this.uiState.filters.list.name,
                        email:this.uiState.filters.list.email,
                        country:this.uiState.filters.list.country,
                        abaker_min:this.uiState.filters.list.abaker_min,
                        abaker_max:this.uiState.filters.list.abaker_max,
                        day_min:this.uiState.filters.list.days_min,
                        days_max:this.uiState.filters.list.days_max
                    },this.getAuthHeader()).then((response) => {
                        this.players.list = [];
                        for (let i in response.data) {
                            let item = response.data[i];
                            console.log(item);
                            this.players.list.push(
                                [
                                    item.id,
                                    item.name,
                                    countryCodeEmoji(item.country || 'cl'),
                                    Math.round((new Date() - new Date(item.date_registered))/(1000*60*60*24)),
                                    item.abaker                        
                                ]
                            )
                        }
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );
            },            
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                console.log(adminCookie);
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
            //this.filters = localStorage.getItem("filters");
        },
        unmounted: function() {
            //localStorage.setItem("filters", this.filters);                  
        }
    }
</script>

<style lang="scss" scoped>
    .filtersBar {
        display: flex;
        flex-wrap: wrap;
        background-color: $c5;
        padding:0.5em;
        border-radius: 0.5em;
        justify-content: space-around;
        margin-bottom: 1em;

    }

    ::v-deep .resultsTable {
        text-align: center;
        background:$c7;
        width: 100%;
        border-radius: 0.5em;

        tr {
            background:$c6;
        }

        td {
            background: $c0;
        }
    }

    ::v-deep .dataTables_paginate {
        background: red;
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        align-items: center;

        >span {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        >:first-child , >:last-child {
            background:blue;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }

    ::v-deep .dataTables_info {

    }

</style>