<template>
    <div :style="{height:imageSize+'',width:imageSize+'px'}" style="margin:0.1em;">
        <svg viewBox="-50 -50 1100 1100">

            <rect
                :style="{ 
                    fill: diceColor.background,
                    stroke: diceColor.border
                }"
                style="stroke-width:50"
                width="1000"
                height="1000"
                ry="200"      
            />

            <g 
                :style="{ 
                    fill: diceColor.dot, 
                    stroke: diceColor.dotborder 
                    }" 
                style="fill-opacity:1;stroke-width:50"
            >

                <ellipse :visibility="pointsShow[0]" cx="250" cy="300" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[1]" cx="250" cy="500" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[2]" cx="250" cy="700" :rx="buttonRadio" :ry="buttonRadio" />

                <ellipse :visibility="pointsShow[3]" cx="500" cy="300" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[4]" cx="500" cy="500" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[5]" cx="500" cy="700" :rx="buttonRadio" :ry="buttonRadio" />

                <ellipse :visibility="pointsShow[6]" cx="750" cy="300" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[7]" cx="750" cy="500" :rx="buttonRadio" :ry="buttonRadio" />
                <ellipse :visibility="pointsShow[8]" cx="750" cy="700" :rx="buttonRadio" :ry="buttonRadio" />

            </g>

        </svg>
    </div>
</template>

<script>

	export default {
		name: 'DiceSVG',
        props: ['dClass','dNumber','colorClases','imageSize'],
        data: function() {
            return {
                buttonRadio:100,
            }
        },
        computed: {
            diceColor: function() {
                //console.log(colorClases[this.dClass]);
                return this.colorClases[this.dClass];
            },
            pointsShow: function() {
                let faceNumber = parseInt(this.dNumber);
                return [                    
                    [0,2,4,5,6].includes(faceNumber) ? 'visible' : 'hidden',
                    [0].includes(faceNumber) ? 'visible' : 'hidden',
                    [0,3,4,5,6].includes(faceNumber) ? 'visible' : 'hidden',

                    [0,6].includes(faceNumber) ? 'visible' : 'hidden',
                    [0,1,5,3].includes(faceNumber) ? 'visible' : 'hidden',
                    [0,6].includes(faceNumber) ? 'visible' : 'hidden',

                    [0,4,3,5,6].includes(faceNumber) ? 'visible' : 'hidden',
                    [0].includes(faceNumber) ? 'visible' : 'hidden',
                    [0,4,2,5,6].includes(faceNumber) ? 'visible' : 'hidden',
                ];
            }
        },
        methods: {	
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
