




<template>
    <div
        :key = chkId
        :id ="'chk'+chkId"
        :style="{ width: colWidth*XunitSize+'px' ,  transform: transformPosition }"
        draggable="false" 
        >

        <CheckerSVG 
            :colors="vcolors[ownerSrc]"
            :selected="selectedChecker"
            :cclass="cclass"
            :onbar="position[0]==0 || position[0]==25"
        />

         <img v-if ="tips && tips.show && tips.tips.length>0"
              :src="require(`@/assets/img/piedras/tip.svg`)"              
         />

    </div>
</template>

<script>
    
    //import anime from 'animejs';
    var posHelper = require('../../assets/js/positionHelpers');
    import CheckerSVG from './CheckerSVG.vue';

	export default {
		name: 'CheckerComp',
        props: ['chkId','position','cclass','owner',
                'direction','colWidth','colHeight','checkersColor',
                'XunitSize','YunitSize','maxTop',
                'playerID','starting','selectedChecker','tips',
                'forcePoistion','colors'
                ],
        components: { CheckerSVG },
        data: function() {
            return {
                calculatedPath: ""
            }
        },
        emits: ['change-path'],
        computed: {
            classSrc: function() {
                if (this.position[0]==0 || this.position[0]==25)
                    return "b";
                return this.cclass;
            },
            ownerSrc: function() {                                
                return this.checkersColor == parseInt(this.playerID)  ?  this.owner : (this.owner + 1) % 2;                
            },
            vcolors: function() {
                if (this.colors) return this.colors;
                return ( 
                    [
                        {
                        background: "#ceca9d",
                        line: "#986941",
                        object1: "#250e08",
                        object2: "#40190f",
                        color: "#2e130c",
                        },
                        {
                        background: "#2e130c",
                        line: "#492a02",
                        object1: "#c89159",
                        object2: "#c89159",
                        color: "#ceca9d"
                        }
                    ]
                    )
            },
            transformPosition: function() {
                let playerID = this.playerID || 0;
                let nx = this.x(this.position,this.direction);
                let ny = this.y(this.position,playerID);  
                let translateX = (nx*this.colWidth)*this.XunitSize +"px";
                let translateY = (this.maxTop-(ny)*(this.colWidth*this.XunitSize)) +"px";
                return `translate(${translateX},${translateY})`;
            } 
        },
        methods: {	
            path: posHelper.path,
            x: posHelper.x,
            y: posHelper.y      
        },
        watch: {  
        },
        mounted: function() {
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    div {
        position: absolute;
    }

    img {
        position:absolute;
        width:100%;        
    }

</style>
