//enviroment.west.js

/* Version */
export const ABK_BUILD_NUMBER = "2024.06.30.1";
export const DEVELOPMENT_MODE = false;

/* Tag Manager Enabled */
export const GA4TRACK = true;
export const GA4ID = "G-484F09DL51";
export const GA4DEBUGMODE = false;

/* Network and Communication */

const IPADDRESS = "play.abak.me";

export const EDGELOCATIONS = {
    "east":"east.abak.me",
    "west":"west.abak.me"    
}


export const WEBPROTOCOL = "https://";

export const WEBURL = IPADDRESS;
export const WEBPORT = 443;

export const APIURL = IPADDRESS;
export const APIPORT = 443;

export const DASHBOARDURL = "dashboard.abak.me";
export const DASHBOARDPORT = 443;

export const LOCALURL = "west.abak.me";

export const API_INTERNAL_SSLPORT = 3001;
export const API_INTERNAL_PORT = 3000;
export const ENGINEURL = IPADDRESS;
export const ENGINEPORT = 8000;

/* IA Server */

export const FLASK_IA_ANALISIS_SERVER ="172.31.24.189";
export const FLASK_IA_ANALISIS_PORT =5000;
export const FLASK_IA_GAME_SERVER ="172.31.30.14";
export const FLASK_IA_GAME_PORT =5000;


export const USE_IP_BLACKLIST = false;

/* GAME CONSTANTS */

export const DEFAULT_MAX_INACTIVITY = 10;
export const TIME_TO_OFFLINE = 45; // Secconds
export const TIME_TO_STOP_UPDATING = 240; // Secconds
export const TIME_TO_STALED_GAME = 60; // Secconds
export const TIME_TO_RE_INFORM_ONLINE_PLAYER = 240; // Secconds
export const INACTIVITY_TIME_TO_BACKGROUND = 120; // in secconds
export const USE_ENGINE_DB_CACHING = false;

/* UI Timers */

export const GET_STATE_FRECUENCY = 5000; // Milliseconds.
export const GET_OPEN_GAMES_FRECUENCY = 5000; // Milliseconds.
export const WRITE_OPEN_GAMES_FRECUENCY = 5000; // Milliseconds.

/* Local paths */

export const ABAK_GOFAI_PATH = "/home/ec2-user/abak/engine/abakbin/abak-ia2";
//export const ABAK_TD_PATH = "python3 /home/ec2-user/abak/engine/abakbin/abakiatd.py";
//export const ABAK_TDS_PATH = "python3 /home/ec2-user/abak/engine/abakbin/abakia_socket_client.py";
export const ABAK_TD_WEIGHTS_PATH = "/home/ec2-user/abak/engine/abakbin/weights/";
export const ABAK_MATCHES_PATH = "/home/ec2-user/abak/engine/db/matches"
export const ABAK_ENGINE_LOGS_PATH = "/home/ec2-user/abak/engine/logs"
export const HALL_OF_FAME_CACHE_DIR = "/home/ec2-user/abak/engine/db/cache"
export const OPEN_GAMES_CACHE_DIR = "/home/ec2-user/abak/engine/db/cache/open"
export const PM2_LOGS_PATH = "/home/ec2-user/.pm2/logs/";

/* Miscelaneus */

export const WEBMASTEREMAIL = "abakevolution@gmail.com";
export const SHARE_INTERNAL_PATH = "/home/ec2-user/abak/server/public/share/"
export const EJS_TEMPLATES_PATH = "/home/ec2-user/abak/server/app/assets/ejs/templates/"
export const WELCOME_LEVELS = 2;
export const GAME_LOG_PATH = "/home/ec2-user/abak/engine/db/logs/"
export const GAME_ANALYSIS_PATH = "/home/ec2-user/engine/db/analytics/";
export const PAYPAL_CLIENT_ID = "AdMWICjCz_tBwi-r4nZiVzU0p8elqRlbw29P13xHvrEUSaErfe4cyTKvCP6cqNgmKJxYackpFtLj1Tqf";
export const PAYPAL_SUBSCRIPTION_VALUE = 3.99; //US DOLLARS
export const PAYPAL_SUBSCRIPTION_PLAN_ID = "P-5VV977120F469352VMRSCTQY";  //P-58L14170R1887982GMRR2WDQ

/* Health System */

export const CLIENT_ERROR_LOG_DB_FILE = "/home/ec2-user/abak/engine/db/logs/log.txt";
export const BLOCKED_USERS_LOG_FILE = "/home/ec2-user/abak/server/database/errors/blocked.txt";

// Messages Must Be on DATABASE!!!!!!!!!!!!!!!.
export const ABAKER_MESSAGE_ID = { 1: 1, 2: 2, 3: 3 };
export const MIDLIFE_MESSAGE_ID = { 'try-abak': 4, 'share-game': 5, 'game-history': 6 };


/* Players DataBase Credentials */

export const PLAYERS_DB_USER = "bkg2";
export const PLAYERS_DB_PASS = "SuperBackgammon1999!";
export const PLAYERS_DB_NAME = "bkg2v2";
export const PLAYERS_DB_HOST = "127.0.0.1";
export const PLAYERS_DB_PORT = "3306";

/* Game Engine DataBase Credentials */

export const GAMES_DB_USER = "abak";
export const GAMES_DB_PASS = "Superbackgammon1999!";
export const GAMES_DB_NAME = "abakgames";
export const GAMES_DB_HOST = "localhost";
export const GAMES_DB_PORT = "5432";

/* SSL Certificates */

export const SSLPRIVATEKEY ='/etc/letsencrypt/live/play.abak.me/privkey.pem';
export const SSLCERTIFICATE ='/etc/letsencrypt/live/play.abak.me/cert.pem';
export const SSLCHAIN ='/etc/letsencrypt/live/play.abak.me/chain.pem';