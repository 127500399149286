<template>
  <router-view/>
</template>

<style>

body {

    font-family: Arial;
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
    background: rgb(46,98,69);
    background: linear-gradient(309deg, rgba(46,98,69,1) 0%, rgba(3,41,8,1) 100%);
}

</style>