<template>
    <div class="main"> 
        <div class="loading" v-if="uiState.loading"> Loading </div>
        <div class="error" v-if="uiState.error.on"> {{ uiState.error.type || "Error" }} </div>
    </div>
</template>

<script>

	export default {
		name: 'uiMessage',
        props: [ 'uiState' ],
        data: function() {
            return {
            }
        },
        methods: {
        }

	}
</script>

<style lang="scss" scoped>

    .main {
        display: flex;
        justify-content: space-between;
        border-radius: 0.5em;

        >div {
            flex:1;
            border-radius: 0.5em;
            margin: 0.5em;
            padding:0.5em;
            text-align: center;
        }


    }


    .error {
        background:red;
    }

    .loading {
        background:rgb(85, 111, 26);
    }


</style>
