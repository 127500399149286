<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab">

            <div class="todayGames"> 

                <TodayStats :todayStats="todayStats"/>

                <MatchesList v-if="todayMatches.length>0" :matchesList="todayMatches" />

            </div>   


        </div>
        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    
    import TodayStats from "../components/admin/TodayStats.vue";
    import MatchesList from "@/components/admin/MatchesList.vue";

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminGames',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            TodayStats,
            MatchesList,
        
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    daymin:this.$route.query.daymin || 0,
                    daymax:this.$route.query.daymax || 1
                },
                todayStats: {
                    stats: {
                        total: 0,
                    }
                },
                todayMatches: [],
                todayMatchesFiltered: []
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getTodayMatches: function(postData) {
                    console.log("getTodayMatches");
                    this.uiState.loading = true;
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/matches/today/`,postData,this.getAuthHeader()).then((response) => {
                        this.processTodayMatches(response.data);
                        this.uiState.loading = false;
                        }).catch((error) => {
                            this.uiState.loading = false;
                            console.error(error);
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            }
                        );
            },  
            processTodayMatches: function(gameList) {
                this.todayMatches = gameList;   
                this.todayMatchesFiltered = gameList;  
                this.todayStats = this.generateStats();                           
            },
            generateStats: function() {
                const TIMETOENGAGED = 5;
                
                let players = { }; 
                
                let games_types = { abak: 0, classic: 0, practice:0 };
                let games_opponents = { cpu:0 , human: 0 };
                let games_levels = { 1:0, 3:0 , 4:0, 5:0 , 6:0 };                
                let games_time = { 30: 0, 60:0 , 360:0  };
                let games_status = { fi: 0, un: 0 }
                let players_number = { 
                    new: 0,
                    engaged: 0,
                    old: 0,
                }
                for (let tmatch in this.todayMatches) {
                    // Set Player
                    let tm = this.todayMatches[tmatch];
                    console.log(tm);
                    if (!players[tm.p1id]) {
                        let playerCat = tm.p1days == 0 ? 'new' : tm.p1days < 5 ? 'engaged' : 'old';
                        players_number[playerCat]++;
                        players[tm.p1id] = {
                            name:tm.p1name,
                            image:tm.p1image,
                            country:tm.p1country,
                            abaker:tm.p1abaker,
                            days:tm.p1days,
                            count: { abak:0 , practice: 0 , classic: 0 },
                            turns: { abak:0 , practice: 0 , classic: 0 }
                        };
                    }                        
                    players[tm.p1id].count[tm.mtype]++;
                    players[tm.p1id].turns[tm.mtype]+=tm.gturns;

                    if (tm.p2id) {
                        if (!players[tm.p2id]) {
                            let playerCat = tm.p2days == 0 ? 'new' : tm.p2days < TIMETOENGAGED ? 'engaged' : 'old';
                            players_number[playerCat]++;
                            
                            players[tm.p2id] = {
                            name:tm.p2name,
                            image:tm.p2image,
                            country:tm.p2country,
                            abaker:tm.p2abaker,
                            days:tm.p2days,
                            count: { abak:0 , practice: 0 , classic: 0 },
                            turns: { abak:0 , practice: 0 , classic: 0 }
                            };
                        }
                        players[tm.p2id].count[tm.mtype]++;
                        players[tm.p2id].turns[tm.mtype]+=tm.gturns;
                    }
                    
                    let mtypen = tm.mtype ? tm.mtype : 'abak';
                    games_types[mtypen]++;
                    
                    games_opponents[tm.opponentType.toLowerCase()]++;
                    
                    games_status[tm.gid?'fi':'un']++;
                    
                    

                    let minutes = (this.syncTime(Date.now()) - new Date(tm.gcreated))/1000/60;

                    if ( minutes<360) games_time['360']++;
                    if ( minutes<60) games_time['60']++;
                    if ( minutes<30) games_time['30']++;

                    if (tm.opponentType=='HUMMAN') {
                        players[tm.p2id]= tm.p2name;
                    }
                    else {
                        games_levels[tm.opponentLevel]++;                        
                    }                    
                }
                return {
                    players: players,
                    players_number: players_number,
                    stats: {
                        total: this.todayMatches.length,
                        types:games_types,
                        level:games_levels,
                        opponents:games_opponents,
                        time:games_time,
                        status:games_status                        
                    }
                }
            },
            syncTime: function(time) {
                return time;
            },  

        },
        watch: {
            '$route.query': {
            handler(newQuery,oldQuery) {                
                console.log("nq",newQuery);                    
                console.log("oq",oldQuery);
                if (oldQuery && newQuery!=oldQuery) {
                    window.location.reload();
                    }
                }
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getTodayMatches( { daymin:this.filters.daymin,daymax:this.filters.daymax });
            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style>
</style>