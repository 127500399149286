import { createRouter, createWebHistory } from 'vue-router'
import AdminMain from '@/views/AdminMain.vue'
import AdminPlayers from '@/views/AdminPlayers.vue'
import AdminTemplate from '@/views/AdminTemplate.vue'
import AdminMessagesCreate from '@/views/AdminMessagesCreate.vue'
import AdminMessagesSend from '@/views/AdminMessagesSend.vue'
import AdminMessagesList from '@/views/AdminMessagesList.vue'
import AdminOnlineHistoric from '@/views/AdminOnlineHistoric.vue'
import AdminPlayerInfo from '@/views/AdminPlayerInfo.vue'
import AdminGames from '@/views/AdminGames.vue'
import AdminGamesCurrent from '@/views/AdminGamesCurrent.vue'
import AdminGamesHistoric from '@/views/AdminGamesHistoric.vue'
import AdminNewPlayers from '@/views/AdminNewPlayers.vue'
import AdminEngagedPlayers from '@/views/AdminEngagedPlayers.vue'
import AdminPlayersPreferences from '@/views/AdminPlayersPreferences.vue'
import AdminStatus from '@/views/AdminStatus.vue'
import AdminDesigner from '@/views/AdminDesigner.vue'
import AdminInvitations from '@/views/AdminInvitations.vue'
import AdminFriendship from '@/views/AdminFriendship.vue'
import AdminChat from '@/views/AdminChat.vue'
import AdminAIUsage from '@/views/AdminAIUsage.vue'
import AdminOnlineCombined from '@/views/AdminOnlineCombined.vue'
import AdminLogsView from '@/views/AdminLogsView.vue'

const routes = [
  {
    path: '/',
    name: 'Admin',
    component: AdminMain,
    meta: {
      title: "Dashboard - Abak Evolution Backgammon."
    }
  },
  {
    path: '/status',
    name: 'AdminStatus',
    component: AdminStatus,
    meta: {
      title: "Dashboard - Server Status."
    }
  },
  {
    path: '/status/logs',
    name: 'AdminLogView',
    component: AdminLogsView,
    meta: {
      title: "Dashboard - Logs."
    }
  },  
  {
    path: '/players/list',
    name: 'PlayersList',
    component: AdminPlayers,
    meta: {
      title: "Dashboard - Players List."
    }
  },
  {
    path: '/players/info',
    name: 'PlayersInfo',
    component: AdminPlayerInfo,
    meta: {
      title: "Dashboard - Player Info."
    }
  },
  {
    path: '/players/new',
    name: 'AdminNewPlayers',
    component: AdminNewPlayers,
    meta: {
      title: "Dashboard - New Players."
    }
  },
  {
    path: '/players/engaged',
    name: 'AdminEngagedPlayer',
    component: AdminEngagedPlayers,
    meta: {
      title: "Dashboard - Engaged."
    }
  },
  {
    path: '/players/preferences',
    name: 'AdminPlayerPreferences',
    component: AdminPlayersPreferences,
    meta: {
      title: "Dashboard - Preferences ."
    }
  },
  {
    path: '/games/stats',
    name: 'AdminGame',
    component: AdminGames,
    meta: {
      title: "Dashboard - Games Stats."
    }
  },  
  {
    path: '/games/current',
    name: 'AdminGamesCurrent',
    component: AdminGamesCurrent,
    meta: {
      title: "Dashboard - Games Current."
    }
  },  
  {
    path: '/games/historic',
    name: 'AdminGamesHistoric',
    component: AdminGamesHistoric,
    meta: {
      title: "Dashboard - Games Current."
    }
  },  
  {
    path: '/template',
    name: 'Template',
    component: AdminTemplate,
    meta: {
      title: "Dashboard - AdminTemplate."
    }
  },
  {
    path: '/messages/list',
    name: 'ListMessages',
    component: AdminMessagesList,
    meta: {
      title: "Dashboard - List Message."
    }
  },
  {
    path: '/messages/create',
    name: 'createMessage',
    component: AdminMessagesCreate,
    meta: {
      title: "Dashboard - Create Message."
    }
  },
  {
    path: '/messages/send',
    name: 'SendMessage',
    component: AdminMessagesSend,
    meta: {
      title: "Dashboard - Send Message."
    }
  },
  {
    path: '/online/historic',
    name: 'OnlineHistoric',
    component: AdminOnlineHistoric,
    meta: {
      title: "Dashboard - Online Historic."
    }
  },
  {
    path: '/online',
    name: 'OnlineCombined',
    component: AdminOnlineCombined,
    meta: {
      title: "Dashboard - Online Combined."
    }
  },
  {
    path: '/interactions/invitations',
    name: 'AdminInvitations',
    component: AdminInvitations,
    meta: {
      title: "Dashboard - Invitations."
    }
  },    
  {
    path: '/interactions/friendship',
    name: 'AdminFriendship',
    component: AdminFriendship,
    meta: {
      title: "Dashboard - Friendship."
    }
  }, 
  {
    path: '/interactions/chat',
    name: 'AdminChat',
    component: AdminChat,
    meta: {
      title: "Dashboard - Chat."
    }
  },     
  {
    path: '/ai/usage',
    name: 'AIUsage',
    component: AdminAIUsage,
    meta: {
      title: "Dashboard - Chat."
    }
  },     
  {
    path: '/designer',
    name: 'AdminDesigner',
    component: AdminDesigner,
    meta: {
      title: "Abak Board Designer."
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
