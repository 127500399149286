<template>
    <div> 
        <div><NavigationMenu /></div> 

        <h2>Send Messages </h2>
        <div class="tab"> 

            <h4>Send Type </h4>

            <div class="container2"> 
                <label for="mtype"> Send Type</label>
                <select id="mtype" v-model="selected_receiver_type"> 
                    <option v-for="i,k in receivers" :key="'mt_'+k"> 
                        {{ k }}
                    </option>
                </select>
                <label for="msgid"> Message ID</label>
                <input size="3" type="text" id="msgid" v-model="message_id" />
                <label for="sender_id">Sender</label>
                <input size="3" type="text" id="sender_id" v-model="sender_id" />
            </div>

            <div v-if="message.preview" class="container message_preview">                
                <div class="header"> 
                    <div>
                        <div><div>id</div>{{ message.preview[0].mid }}</div>
                        <div><div>Name</div>{{ message.preview[0].mname }}</div>
                        <div><div>Description</div>{{ message.preview[0].mdescription }}</div>
                    </div>
                    <div> 
                        <div><div>Image</div>{{ message.preview[0].mimage }}</div>
                        <div><div>Locked</div>{{ message.preview[0].mlocked }}</div>
                        <div><div>Action</div>{{ message.preview[0].maction }}</div>

                    </div>
                </div>
                <div class="texts"> 
                    <div v-for="mt in message.preview" :key="mt.mttid"> 
                        <div> 
                            <div>{{ mt.locale }} </div>
                            <div>{{ mt.title }} </div>
                            <div class="count"> 
                                <div> {{ mt.title.length  }}</div> 
                                <div> {{ mt.text.length  }} </div>
                            </div>
                        </div>                            
                        <div>{{ mt.text }} </div>
                    </div>
                </div>
            </div>


            <h4>Send Options </h4>

            <div class="container" v-if="selected_receiver_type=='personal'"> 
                <label for="rpid">{{ receivers.personal.player_id.label }}</label>

                <input type="number" v-model="playerToAdd" placeholder="PlayerID">
                <button @click="addPlayer">Add PlayerID</button>
                <button @click="clearPlayers">Clear List</button>
                <ul>
                    <li v-for="(number, index) in receivers.personal.player_id.value" :key="index">{{ number }}</li>
                </ul>
            </div>

            <div class="container" v-if="selected_receiver_type=='type'"> 
                <table>
                    <tr v-for="rt,k in receivers.type" :key="'rti_'+k">
                        <td>{{rt.label}}</td>
                        <td><input size="3" type="text" v-model="receivers.type[k].value"></td> 
                    </tr>                
                </table> 
        </div>

            <h4>Action </h4>
            <div class="container2">
                <label for="ms_action_value_icon"> Action Icon </label>                        
                <select id="ms_action_value_icon" v-model="message.content.action.value.icon"> 
                    <option v-for="i,k in images" :key="'mim_'+k">                                 
                        <p>{{i}}</p>
                    </option>
                </select>

                <label for="ms_action_value_text"> Action Text </label>
                <input type="text" id="ms_action_value_text" v-model="message.content.action.value.text">
                <label for="ms_action_value_menu"> Action Menu </label>
                <input disabled type="text" id="ms_action_value_menu" v-model="message.content.action.value.menu">
            </div>
                <div class="container2">
                <label for="ms_action_value_data_show"> Show </label>
                <select id="ms_action_value_data_show" v-model="message.content.action.value.data.show"> 
                    <option :value="true">{{true}}</option>
                    <option :value="false">{{false}}</option>
                </select>                        
                <label for="ms_action_value_data_section"> Section </label>                        
                <select id="ms_action_value_data_section" v-model="message.content.action.value.data.section"> 
                    <option v-for="i,k in action_sections[message.content.action.name]" :key="'mim_'+k">                                 
                        <p>{{i}}</p>
                    </option>
                </select>                        
                <label for="ms_action_value_data_stonePage"> Stone Id </label>
                <input type="text" id="ms_action_value_data_stonePage" v-model="message.content.action.value.data.stoneId">
                <label for="ms_action_value_data_stonePage"> Stone Page </label>
                <input type="text" id="ms_action_value_data_stonePage" v-model="message.content.action.value.data.stonePage">
            </div>            

            <div class="buttons">
                <button @click="sendMessage()">Send Message</button>
            </div>

            <div v-if="uiState.loading" class="message"> 
                Sending Message (Working)
            </div>
            <div v-if="response && selected_receiver_type=='type' && parseInt(receivers.type.test_only.value) == 1">
                <div class="message_ok"> 
                    Message Will be send to {{ response ? response[0].length : 0  }} players.
                </div>
                <DataTable>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>days_registered</th>
                            <th>Logins</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="p,k in response[0]" :key="'sp_'+k"> 
                            <td>{{ p.id }}</td>
                            <td>{{ p.name }}</td>
                            <td>{{ p.days_registered }}</td>
                            <td>{{ p.logincount }}</td>
                        </tr>
                    </tbody>
                </DataTable> 
            </div>
            <div v-if="response && (selected_receiver_type=='personal' || parseInt(receivers.type.test_only.value) == 0)" class="message"> 
                Message Sent to {{ response.affectedRows }} players.
            </div>
            <div v-if="uiState.message.show" class="message"> 
                {{ uiState.message.text }}
            </div>
        </div>
        


        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net';    

    DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminSendMessages',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            DataTable
        },
        data: function() {
            return {
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    },
                    filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                    },
                    message: {
                        show:false,
                        text:"",
                    }

                },
                sender_id: 0,
                selected_receiver_type:"personal",
                message_id:  this.$route.query.mid,
                playerToAdd:null,
                receivers: {
                    personal: {
                        player_id:{
                            label:"Player ID",
                            value:[]
                        }
                    },
                    type:{
                        min_matches:{
                            label:"Min Matches",
                            value:10,
                        },
                        days_registered:{
                            label:"Days Registered",
                            value:10,
                        },
                        weeks_active:{
                            label:"Weeks Active",
                            value:6,
                        },
                        test_only: {
                            label:"Test Only",
                            value:1
                        }
                    }
                },
                message: {
                    content: {
                        action:{
                            name:"changeMenu",
                            value: {
                                icon: "computer",
                                text: "Preferences", 
                                menu: "configMenu", 
                                data: { 
                                    show:true,
                                    section:"board", 
                                    stoneId:0,
                                    stonePage:0 
                                }
                            }
                        }
                    },
                    preview: null
                },
                response: null,
                images: [
                    '','addfriend','brain','cake','calendar','chartup','combinatory','computer','connected',
                    'delete-clock','down','druida_atrapa','druida_come','faq','friendsgroup','goal',
                    'hall_icon','heart','help','karate','level','logopalestino','match','medal','nose',
                    'no','note','nrplayers','panel','payments','people','practice','register',
                    'removefriend','rules','rule','si','stake','star','start','stats','stickers',
                    'target','time','trophy','type_abak','type_classic','type_practice','up','versus',
                    'visibility','visitor','world'
                ],
                actions: [
                    '',
                    'changeMenu'
                ],
                action_sections: {
                    changeMenu: [
                        'board',
                        'uiOptions',
                        'acelerators',
                        'rules',
                        'p2p'
                    ]
                },                
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            addPlayer() {
                if (this.playerToAdd !== null && this.playerToAdd !== '') {
                    this.receivers.personal.player_id.value.push(Number(this.playerToAdd));
                    this.playerToAdd = null;
                }
            },
            clearPlayers() {
                this.receivers.personal.player_id.value=[]
            },
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getMessage: function() {
               
                let data = {
                    action: 'message-detail',
                    message_id: this.message_id
                }
                this.axios.post(`${WEBPROTOCOL}${this.uiState.filters.locations.current}:${APIPORT}/admin/messages/get/`,data,this.getAuthHeader()).then((response) => {
                        this.message.preview = response.data;                         
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.log(error);
                        }
                    );
            },
            sendMessage: function() {
                    this.uiState.loading = true;
                    this.response = null;
                    this.axios.post(`${WEBPROTOCOL}${this.uiState.filters.locations.current}:${APIPORT}/admin/messages/send/`,{
                        type:this.selected_receiver_type,
                        sender_id:this.sender_id,
                        message_id:this.message_id,
                        receivers: this.receivers,
                        action_value: JSON.stringify(this.message.content.action.value)
                    },this.getAuthHeader()).then((response) => {
                        this.response = response.data;
                        this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );
            },            
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                    this.getMessage()

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss" scoped>
    .tab {
        //border: 1px solid white;
        padding:0.5em;
        background-color: $c7;
        overflow:auto;
    }

    .message {
        background:red;
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:1em;
    }

    .message_ok {
        background:rgb(81, 101, 15);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:1em;
    }

    .container2 {

        background:rgb(162, 195, 40);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .container {
        background:rgb(147, 174, 50);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
        align-items: center;
    }

    .buttons {
        background:rgb(174, 162, 50);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .message_preview {
        background:rgb(31, 40, 3);
        border-radius: 0.5em;
        .header>div {
            display: flex;
            justify-content: space-between;
            text-align: center;
            border-radius: 0.5em;
            div {
                padding:0.5em;                
            }            
            >div>div:first-child {
                background:rgb(35, 5, 5);
            }
            >div {
                
            }

        }
        .texts {
            text-align: center;
            margin-top: 0.5em;
            background:rgb(31, 40, 3);
            border-radius: 0.5em;
            padding:0.5em;
            >div>div:first-child {
                border:0.1em solid black;
                align-items: center;
                background: rgb(15, 15, 15);
                margin-top:0.5em;
                border-radius: 0.5em;
                >div:first-child {
                    background:black;
                    padding:0.4em;
                }
                display: flex;
                justify-content: space-around;
                margin-bottom:0.5em;
            }

            .count {
                display: flex;
                div {
                    background-color: rgb(39, 6, 10);
                    margin: 0.2em;
                    padding:0.3em;
                    border-radius: 0.5em;

                }
            }
        }

    }

    ::v-deep .resultsTable {
        text-align: center;
        background:$c7;
        width: 100%;
        border-radius: 0.5em;

        tr {
            background:$c6;
        }

        td {
            background: $c0;
        }
    }

    ::v-deep .dataTables_paginate {
        background: red;
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        align-items: center;

        >span {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        >:first-child , >:last-child {
            background:blue;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }


</style>