<template>
    <div style="position:relative"> 

        <div class="detail" v-if="selected">
            <div><div>t</div><div>{{ JSON.parse(invitations[selected].value).type }}</div></div>
            <div><div>t</div><div>{{ JSON.parse(invitations[selected].value).maxTimeOut }}</div></div>
            <div><div>g</div><div>{{ JSON.parse(invitations[selected].value).goal }}</div></div>

        </div>
        
        <table>
            <thead> 
                <td> Date </td>            
                <td> type </td>            
                <td> Sender </td>            
                <td> Receiver </td>            
                <td> Resp </td>            
            </thead>
            <tbody>
                <tr v-for="invitation,i in invitations" 
                    @click="selected==i?selected=0:selected=i"
                    :key="invitation.id">
                    <td style="font-size:0.6em;"> 
                        <DateTimeDisplay :datetime="new Date(invitation.created_at)"/>
                    </td>
                    <td style="font-size:0.6em;"> 
                        {{ invitation.action }}
                    </td>
                    <td> 
                        <div> 
                            {{invitation.psname}}
                        </div>
                        <div> 
                            {{countryCodeEmoji(invitation.pscountry)}}
                            <img style="width:1.2em;" :src="invitation.psimage" />
                        </div>
                    </td>
                    <td> 
                        <div> 
                            {{invitation.prname}}
                        </div>
                        <div> 
                            {{countryCodeEmoji(invitation.prcountry)}}
                            <img style="width:1.2em;" :src="invitation.primage" />
                        </div>
                    </td>
                    <td> 
                        {{ !invitation.response ? "-" : invitation.response == 1 ? "❌" : "✅"   }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

    import DateTimeDisplay from './DateTimeDisplay.vue';
    const { countryCodeEmoji} = require('country-code-emoji');

	export default {
		name: 'InvitationsView',
        props: ['invitations'],
        components: { DateTimeDisplay },
        data: function() {
            return {
                selected: null
            }
        },
        methods: {
            countryCodeEmoji
        }

	}
</script>

<style lang="scss" scoped>

        
        table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        

        .detail {
            display: flex;
            justify-content: space-around;
            position:fixed;
            background:$c6;
            width:100%;
            height: 5em;
            padding:0.3em;
            >div {
                background:$c5;
                padding:1em;
            }
        }


</style>
