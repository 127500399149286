<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab" v-if="statusData">        
            <div> <button @click="getServerStatus()"> Refresh </button></div>
            <div>Server</div>
            <div class="statusItems">
                <div>Status</div>        
                <div>{{statusData.server.status}}</div>
                <div>{{statusData.server.uptime_human}}</div>
            </div>
            <div>Requests</div>
            <div class="statusItems">
                <div>R</div>        
                <div><span>T</span> {{statusData.server.requests.total}}</div>
                <div><span>1m</span> {{statusData.server.requests.last_minute}}</div>
                <div><span>5m</span> {{statusData.server.requests.last_5mn_avg}}</div>
                <div><span>15m</span> {{statusData.server.requests.last_15mn_avg}}</div>
            </div>
            <div>System Load </div>
            <div class="statusItems">
                <div>Load:</div>        
                <div><span>1m</span>{{statusData.system.loadavg[0]}}</div>
                <div><span>5m</span>{{statusData.system.loadavg[1]}}</div>
                <div><span>15m</span>{{statusData.system.loadavg[2]}}</div>
            </div>
            <div class="statusItems">        
                <div>FreeMem:</div>
                <div>{{statusData.system.freeMemory}}</div>
            </div>
        </div>    

    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminStatus',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                statusData: null,
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                }
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getServerStatus: function() {
                    this.uiState.loading = true;
                    this.uiState.tabShow = "status";
                    this.axios.get(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/status/` ,this.getAuthHeader()).then((response) => {
                        this.statusData = response.data;
                        this.uiState.loading = false;
                        }).catch((error) => {
                            this.uiState.error = { on:true, type : error.message };
                            setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            this.uiState.loading = false;
                            console.error(error);
                            }
                        );
            },
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
            this.getServerStatus();
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">

    .tab {
            //border: 1px solid white;
            padding:0.5em;
            background-color: $c7;
            overflow:auto;
        }

    .statusItems {
        display:flex;
        >div:nth-child(1) {
            background-color:$c8;
        }
        >div {
            background-color:$c5;
            padding:0.3em;
            border-radius: 0.3em;
            margin:0.2em;
        }
        span {
            background-color:$c3;
            padding: 0.05em 0.5em;
            font-size:0.8em;
        }
    }


</style>