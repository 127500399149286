<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
        <div class="tab"> 
            <div class="menu">
                <div> Registered After <input size="1" type='text' v-model='filters.engaged.regdaysmax'/> days </div>
                <div> Registered Before <input size="1" type='text' v-model='filters.engaged.regdaysmin'/> days </div>
                <div> Played in the last <input size="1" type='text' v-model='filters.engaged.maxdays'/> days </div>
                <div> At least <input size="1" type='text' v-model='filters.engaged.mingames'/> days </div>
                <button @click="getEngagedPlayers()"> Search </button>
            </div>
            <div v-if="engagedPlayers.length>0"> 
                <DataTable class="display" :data="engagedPlayersData">
                    <thead>
                        <tr> 
                            <td>Name</td>
                            <td>Cy</td>
                            <td>D%</td>
                            <td>D#</td>
                            <td>Games</td>
                            <td>Days</td>
                        </tr>
                    </thead>
                </DataTable>
            </div>        
        </div>
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    const { countryCodeEmoji} = require('country-code-emoji');
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net';    

    DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminEngagedPlayer',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                engagedPlayers: [],
                engagedPlayersData:[],
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        }, 
                    engaged: {
                        regdaysmax:10,
                        regdaysmin:3,
                        maxdays:10,
                        mingames:2,
                    },
                },
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            countryCodeEmoji,
            syncTime: function(time) {
                return time;
            },  
            formatedDateDiff: function(dateEnd,dateStart,printUnit=true) {
                if (typeof dateStart != 'object') dateStart = new Date(dateStart);
                if (typeof dateEnd != 'object') dateEnd = new Date(dateEnd);
                let timedif = (dateEnd.getTime() - dateStart.getTime())/1000;
                if (timedif<60) return `${parseInt(timedif)} s`;
                if (timedif<60*60) return `${(timedif/60).toFixed(2)}${ printUnit ? ' m':"" }`;
                if (timedif<60*60*24) return `${(timedif/60/60).toFixed(1)}${ printUnit ? ' h':"" }`;
                return `${(timedif/60/60/24).toFixed(1)}${ printUnit ? ' d':"" }`;
            },
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getEngagedPlayers: function() {
                this.uiState.loading = true;
                this.uiState.tabShow = "engaged";
                const queryparams = 
                    { 
                        daysusermax: parseInt(this.filters.engaged.regdaysmax), 
                        daysusermin: parseInt(this.filters.engaged.regdaysmin), 
                        daysgames:parseInt(this.filters.engaged.maxdays), 
                        mindays: parseInt(this.filters.engaged.mingames),
                    };
                this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/engaged/`,queryparams,this.getAuthHeader()).then((response) => {
                    console.log(response.data);
                    this.engagedPlayers = response.data;
                    let engagedPlayersTemp = []
                    for (let ep in this.engagedPlayers) {
                        let p = this.engagedPlayers[ep];                        
                        engagedPlayersTemp.push( [
                            p.name,
                            countryCodeEmoji(p.country),
                            Math.round(p.dnorm*100)+"%",
                            p.dnumber,
                            p.gnumber,
                            this.formatedDateDiff(this.syncTime(Date.now()),p.pcreated,false)
                        ])                    
                    }
                    this.engagedPlayersData = engagedPlayersTemp;
                    this.uiState.loading = false;
                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.error(error);
                        this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                        }
                    );

            },
            redirectToUrl: function(url) {
                this.$router.push(url);
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style lang="scss">


.player {
        margin:0.5em;
        background-color: $c6;
        min-width: 7em;      
        border-radius: 0.5em;                  
        .playername {
            border-radius:0.5em 0.5em 0 0;
            background-color:$c8;
            text-align:center;
            padding:0.3em;            
        }
        >div:nth-child(2) {
            padding-top:0.5em;            
        }        
        >div:nth-child(4) {
            padding:1em;            
        }
    }

    .boxWinner {
        border:2px solid rgb(224, 194, 24);
    }

    .playerHead {
        display:flex;
        justify-content: space-around;
    }

    .players {
        display:flex;
        flex-wrap: wrap;
        margin:0;
    }
    

</style>