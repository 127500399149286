<template>
    <div> 
        <div class="filters"> 
            <div> AI Usage </div>
            <div> 
                <div @click="filters.period ='hour'" :class="{ active: filters.period =='hour'}"> Hour </div>
                <div @click="filters.period ='minute'" :class="{ active: filters.period =='minute'}"> minute </div>
            </div>
            <div> 
                <div @click="filters.data ='Count'" :class="{ active: filters.data =='Count'}"> Count </div>
                <div @click="filters.data ='Time'" :class="{ active: filters.data =='Time'}"> Time </div>
            </div>

            <div> 
                <div @click="filters.server ='game'" :class="{ active: filters.server =='game'}"> Game </div>
                <div @click="filters.server ='analysis'" :class="{ active: filters.server =='analysis'}"> Analysis </div>
            </div>

            <div @click="getAIUsage()"> Fetch </div>
        </div>
        <div v-if="uiState.loading"> loading </div>
        <div v-else>
            <div v-if="uiState.fetchDone"> 
                <Line
                    :chart-options="chartOptions"
                    :chart-data="stats.data"
                    :chart-id="'23rwer'"
                    :height="150"
                />
            </div> 
        </div>
    </div>
</template>

<script>

    //import DateTimeDisplay from './DateTimeDisplay.vue';
    const { countryCodeEmoji} = require('country-code-emoji');
    const { APIURL,APIPORT,WEBPROTOCOL, 
            FLASK_IA_ANALISIS_SERVER, FLASK_IA_ANALISIS_PORT, 
            FLASK_IA_GAME_SERVER, FLASK_IA_GAME_PORT } = require('../../assets/js/enviroment.js');
    import { Line } from 'vue-chartjs'
    import {  Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from 'chart.js'
    
    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        PointElement,
        CategoryScale
    )

    function generateDateRange(start, end, period) {
        let dates = [];
        let currentDate = new Date(start);
        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate.setMinutes(currentDate.getMinutes() + period);
        }

        return dates;
    }


	export default {
		name: 'AIServerUsage',
        props: ['adminRights'],
        components: { 
            Line
            //DateTimeDisplay 
        },
        data: function() {
            return {
                uiState: {
                    loading: false,
                    mode:'stats',
                    fetchDone:false,
                },
                stats: {
                    raw: [],
                    data: [],
                },
                filters: {
                    period: 'hour',
                    data: 'Count',
                    server: 'game'
                },
                chartOptions: {
                    responsive: true
                },
            }
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            filterData: function() {
                console.log("filter");
            },
            processArray: function(arr) {


                console.log(arr);
                if (arr.length === 0) {
                    return { dates: [], counts: [] };
                }

                arr.sort((a, b) => new Date(a.Datetime) - new Date(b.Datetime));

                let startDate = new Date(arr[0].Datetime);
                let endDate = new Date(arr[arr.length - 1].Datetime);

                let periodSize = this.filters.period == 'hour' ? 60 : 1;
                let allDates = generateDateRange(startDate, endDate, periodSize);
                let dates = [];
                let counts = [];

                for (let date of allDates) {
                    let currentDateString = date.toISOString().slice(0, 19).replace("T", " ");

                    let index = arr.findIndex(obj => new Date(obj.Datetime).toISOString().slice(0, 19).replace("T", " ") === currentDateString);

                    let currentDatePrint = (this.filters.period != 'hour') ?  
                        currentDateString.split(' ')[1].slice(0, 5): 
                        `${currentDateString.split(' ')[0].split('-')[2]} ${currentDateString.split(' ')[1].slice(0, 2)}h`;

                    if (index>-1) {
                        dates.push(currentDatePrint);
                        counts.push(parseFloat(arr[index][this.filters.data]));
                        index++;
                    } else {
                        dates.push(currentDatePrint);
                        counts.push(0);
                        index++;
                    }
                }
                let chartData = {
                    labels: dates,
                    datasets: [
                        { 
                            label:"game",
                            data:counts,
                            backgroundColor: 'red',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'red',
                            pointHoverBorderColor: 'red',                            
                        }
                    ]                    
                }
                
                return chartData;
            },

            getAIUsage: function() {
                const periods = {
                    'hour':'usage_by_hour_48',
                    'minute':'usage_by_minute_48',
                }
                const servers = {
                    'analysis': FLASK_IA_ANALISIS_SERVER,
                    'game' : FLASK_IA_GAME_SERVER
                }
                const ports = {
                    'analysis': FLASK_IA_ANALISIS_PORT,
                    'game' : FLASK_IA_GAME_PORT
                }
                this.uiState.loading = true;
                let data = {
                    file: periods[this.filters.period],
                    server: servers[this.filters.server],
                    port: ports[this.filters.server]
                }
                this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/admin/aistats/get/`,data,this.getAuthHeader()).then((response) => {
                        this.stats.raw = response.data;
                        this.stats.data = this.processArray(response.data); 
                        this.filterData();
                        this.uiState.fetchDone = true;
                        this.uiState.loading = false;
                    }
                    ).catch((error) => {
                        this.uiState.loading = false;
                        console.log(error);
                        }
                    );
            }
        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }

	}
</script>

<style lang="scss" scoped>

        table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        

        .detail {
            display: flex;
            justify-content: space-around;
            position:fixed;
            background:$c6;
            width:100%;
            height: 5em;
            padding:0.3em;
            >div {
                background:$c5;
                padding:1em;
            }
        }


        .todayGames {
        text-align: center;  
        width: 100%;      
    }

    .todayGame {
        background-color:$c6;
        margin:0.5em;        
        border-radius: 0.5em;
        >div {
            display:flex;
            align-items:center;
            justify-content: space-around;
        }
    }

    .matchInfo {
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-direction: column;
        >div {
            display:flex;            
        }

        >div:nth-child(2)>div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
        }
        >div:nth-child(3){
            >div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
            }
            :nth-child(1) {
                background: $c0;
            }
            :nth-child(2) {
                background: $checkerwhite;
            }
            :nth-child(3) {
                background: $checkerblack;
            }
        }
    }

    .dateContainer {
        text-align:center;
    }

    .detailHide {
        display:none;
    }

    .gameOverView1 {
        display:flex;
        justify-content: space-around;
        >div {
            margin:0.3em;
            background-color: $c8;
            padding:0.3em;
        }
        text-align: center;
    }

    .statsContainer {

        .level1Container {
            >div.title {
                background:$c9;
            }
            background:$c6;
            >div:last-child {
                display: flex;
                flex-wrap: wrap;
            }
            margin:0.5em;
        }

        .level2Container {
            margin:0.5em;
            background:$c5;
            >div:last-child {
                display: flex;
            }
        }

        .valueContainer {
            background:$c4;
            margin:0.2em;
            padding: 0.3em;
            >div:last-child {
                background:$c0;
                padding: 0.3em;
            }
        }

    }

    .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

   .cpuContainer {
       background-color:$c11;
       border-radius: 0.5em;
       padding: 1em;

       >div:nth-child(2) {
           background-color: $c3;
           border-radius: 0.3em;
           color:black;
       }
   }

   .statsPlayersControls {
        background-color:#5a4f2b;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1em;
        margin-top:0.3em;
        :last-child {            
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    .filters {
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        box-sizing: border-box;
        flex-wrap: wrap;
        >div {
            background: $c8;
            padding: 0.3em;
        }
    }

    .active {
        background:red;
    }

    .messageDelivery {
        >div {
            >div {
                padding:0.5em;
                flex-grow: 0.3;
            }
            >div:last-child {
                border-radius: 0.3em;
                background: red;
                >div {
                    display: flex;
                    justify-content: space-around;
                    >div:first-child {
                        background:red;
                    }
                }
            }
        }
    }


</style>
