<template>

    <div>
        <div class="filters"> 
            <div @click="filterChats('global');"> 
                <div> Globals</div>
            </div>
            <div @click="filterChats('session');"> 
                <div> Sessions </div>
            </div>
        </div>
        <table> 
            <thead>
                <td>Date</td>
                <td>From</td>
                <td>To</td>
                <td>Text</td>
            </thead>
            <tbody>
                <tr v-for="chat,i in chatsFiltered" :key="'chat'+i">
                    <td style="font-size:0.6em;"> 
                        <DateTimeDisplay :datetime="new Date(chat.date_sent)"/>
                    </td>
                    <td>
                        <div> 
                            {{chat.psname}}
                        </div>
                        <div> 
                            {{countryCodeEmoji(chat.pscountry)}}
                            <img style="width:1.2em;" :src="chat.psimage" />
                        </div>
                    </td>
                    <td v-if="chat.prid">
                        <div> 
                            {{chat.prname}}
                        </div>
                        <div> 
                            {{countryCodeEmoji(chat.prcountry)}}
                            <img style="width:1.2em;" :src="chat.primage" />
                        </div>
                    </td>
                    <template v-else> 
                        <td v-if="chat.session_id">{{ chat.session_id }}</td>
                        <td v-else>Global</td>
                    </template>
                    <td> {{ chat.text }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

const { countryCodeEmoji} = require('country-code-emoji');
import DateTimeDisplay from './DateTimeDisplay.vue';


	export default {
		name: 'ChatsView',
        props: ['chats'],
        components: {DateTimeDisplay},
        data: function() {
            return {
                chatsFiltered: [],
                filters: {
                    onlyGlobals:true,
                    onlySessions:true,
                    madDays:10,
                    minDays:1,
                }
            }
        },
        methods: {
            countryCodeEmoji,
            filterChats: function(action) {
                if (action=='global') {
                    this.filters.onlyGlobals = !this.filters.onlyGlobals;
                    if (this.filters.onlyGlobals) this.filters.onlySessions = false;
                }
                if (action=='session') {
                    this.filters.onlySessions = !this.filters.onlySessions;
                    if (this.filters.onlySessions) this.filters.onlyGlobals = false;

                }
                console.log("chatsfilter");
                this.chatsFiltered= [];
                for (let c in this.chats) {
                    const chat = this.chats[c];
                    if (chat.session_id == '' || chat.session_id == null ) {
                        if (!this.filters.onlySessions) { this.chatsFiltered.push(chat) }
                    }
                    else {
                        if (!this.filters.onlyGlobals) { this.chatsFiltered.push(chat) }
                    }
                }
            }
        },
        mounted: function() {
            this.$nextTick(function () {
                this.chatsFiltered = this.chats;
            });
        }

	}
</script>

<style lang="scss" scoped>

table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

thead {
    padding:0.2em;
    td {
        border-bottom: 1px solid black;
        padding: 0.3em;
        margin: 0.3em;
        background: none;
    }
}
td {
}
tbody tr:nth-child(even) {
    background:darken($c4,10%);
}        

.detail {
    display: flex;
    justify-content: space-around;
    position:fixed;
    background:$c6;
    width:100%;
    height: 5em;
    padding:0.3em;
    >div {
        background:$c5;
        padding:1em;
    }
}

    .filters {
        display:flex;
        justify-content: space-around;
        background: $c8;
        padding:0.5em;
        border-radius: 0.5em;
        margin-bottom: 0.5em;
        >div {
            background: $c7;
            padding:0.3em;
            border-radius: 0.5em;
        }
    }


</style>
