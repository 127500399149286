<template>
    <div style="display: flex;"> 
        <div v-if="uiState.mode =='input'" class="InputFrame"> 
          Load Theme
          <textarea v-model="uiState.input" cols="100" rows="30" > 

          </textarea>
          <button @click="theme=JSON.parse(uiState.input);uiState.mode='none'"> 
            Load
          </button>
        </div>
        <div v-if="true==true"> 
            <div> 

              <div class="section"> 
                <div> Mode </div>
                <select v-model="uiState.mode">
                  <option value="theme"> 
                      Theme
                  </option>
                  <option value="analysis"> 
                      Analysis
                  </option>
                </select>

                <div v-if="uiState.mode=='analysis'"> 
                  <div> 
                    <input type="checkbox" v-model="uiState.showChanceSheet"/> Chance Sheet
                  </div>
                  <div> 
                    <input type="checkbox" v-model="uiState.showBoard"/> Board
                  </div>
                  
                </div>
              </div>

              <div class="section" v-if="uiState.mode=='theme'"> 
                  <div> Theme </div>
                  <div> 
                    <div> Files </div>
                    <input type="text" v-model="themeName" />
                    <button @click="saveTheme(themeName);"> Save </button>
                    <button @click="showSavedList=!showSavedList"> Saved List </button>

                    <div v-if="showSavedList"> 
                      <div style="background:blue;margin:1em;color:white;padding:0.5em;" 
                        v-for="t,i in themeList()" :key="i"
                        @click="loadTheme(t)"
                      >
                        {{ t }} 
                      </div>
                    </div>

                  </div>
                  <div> 
                    <div> Input Output </div>
                    <button @click="copyToClipboard();"> Copy </button>
                    <button @click="uiState.mode='input'"> Load From JSON String </button>
                  </div>
                  <div> 
                    <div> Generate </div>
                    <button @click="clickRandomize()"> Random </button>
                    <select v-model="boardImageSize"> 
                      <option value="1200"> 1200 </option>
                      <option value="800"> 800 </option>
                      <option value="400"> 400 </option>
                    </select>                    
                  </div>
                   
              </div>

              <div class="section" v-if="uiState.mode=='analysis'"> 
                  <div> Analysis </div>
                  <div> 
                    <div> Files </div>
                    <input type="text" v-model="themeMap" />
                    <button @click="saveMap(themeMap);"> Save </button>
                    <button @click="showSavedMaps=!showSavedMaps"> Saved List </button>

                    <div v-if="showSavedMaps"> 
                      <div style="background:blue;margin:1em;color:white;padding:0.5em;" 
                        v-for="t,i in mapList()" :key="i"
                        @click="loadMap(t)"
                      >
                        {{ t }} 
                      </div>
                    </div>

                  </div>
                  <div> 
                    <div> Input Output </div>
                    <button @click="copyToClipboard();"> Copy </button>
                    <button @click="uiState.mode='input'"> Load From JSON String </button>
                  </div>
                  <div> 
                    <div> Generate </div>
                    <button @click="clickRandomize()"> Random </button>
                    <select v-model="boardImageSize"> 
                      <option value="1200"> 1200 </option>
                      <option value="800"> 800 </option>
                      <option value="400"> 400 </option>
                    </select>                    
                  </div>
                   
              </div>



            <div  v-if="uiState.mode=='theme'" class="section"> 
                  <div>Main</div>
                  <div> 
                    Frame <ColorPicker v-model:pureColor="theme.background" /> 
                    Background <ColorPicker v-model:pureColor="theme.board.background" /> 
                    Border & bar <ColorPicker v-model:pureColor="theme.board.bar" /> 
                </div>
              </div>
            </div>

            <div  v-if="uiState.mode=='theme'" class="section"> 
                <div> 
                    Points
                </div>
                <div style="display: flex;justify-content: space-between;"> 
                  <div> 
                    ⚪ <ColorPicker v-model:pureColor="theme.board.points[0].background" /> 
                    ⭕ <ColorPicker v-model:pureColor="theme.board.points[0].border" /> 
                  </div>
                  <div> 
                    ⚪ <ColorPicker v-model:pureColor="theme.board.points[1].background" /> 
                    ⭕ <ColorPicker v-model:pureColor="theme.board.points[1].border" /> 
                  </div>
                </div>
              </div>
              <div  v-if="uiState.mode=='theme'" class="section">
                    <div>Dice </div> 
                    <div v-for="d in theme.dice" :key="'d'+d"> 
                      ⚪ <ColorPicker v-model:pureColor="d.background" /> 
                      ⭕ <ColorPicker v-model:pureColor="d.border" /> 
                      • <ColorPicker v-model:pureColor="d.dot" /> 
                      ॰ <ColorPicker v-model:pureColor="d.dotborder" /> 
                    </div>
              </div>
              <div  v-if="uiState.mode=='theme'" class="section">
                <div>Checkers</div> 
                <div> 
                    <div v-for="c in theme.checkers" :key="'c'+c"> 
                      ⚪ <ColorPicker v-model:pureColor="c.background" /> 
                      ⭕ <ColorPicker v-model:pureColor="c.line" /> 
                      ⁕ <ColorPicker v-model:pureColor="c.object1" /> 
                      a <ColorPicker v-model:pureColor="c.color" /> 
                    </div>
                </div>
              </div>
              <div  v-if="uiState.mode=='theme'" class="section">
                <div>Cube</div> 
                <div> 
                    Back <ColorPicker v-model:pureColor="theme.cube.background" /> 
                    Color <ColorPicker v-model:pureColor="theme.cube.color" /> 
                </div>
              </div>
              <div  v-if="uiState.mode=='theme'" class="section">
                    <div> Buttons </div> 
                    <div v-for="c in theme.buttons" :key="'c'+c"> 
                      ⚪ <ColorPicker v-model:pureColor="c.background" /> 
                      ⭕ <ColorPicker v-model:pureColor="c.border" /> 
                      ⁎ <ColorPicker v-model:pureColor="c.hover" /> 
                      - <ColorPicker v-model:pureColor="c.off" /> 
                    </div>
              </div>

              <div  v-if="uiState.mode=='theme'" class="section"> 
                <div> UI Elements</div>
                <div style="display:flex;"> 

                  <div> 
                    <div> Bkg <ColorPicker v-model:pureColor="theme.elements.box1.background" /> </div>
                    <div> Brd <ColorPicker v-model:pureColor="theme.elements.box1.color" /> </div>
                  </div>                   

                  <div> 
                    <div> Bkg <ColorPicker v-model:pureColor="theme.elements.box2.background" /> </div>
                    <div> Brd <ColorPicker v-model:pureColor="theme.elements.box2.color" /> </div>
                  </div>                   

                  <div> 
                    Chrono1
                    <div>  
                        <ColorPicker v-model:pureColor="theme.elements.chrono.total.background" />
                        <ColorPicker v-model:pureColor="theme.elements.chrono.total.color" />                        
                     </div>
                     <div>  
                        <ColorPicker v-model:pureColor="theme.elements.chrono.current.background" />
                        <ColorPicker v-model:pureColor="theme.elements.chrono.current.color" />                        
                     </div>
                  </div>                   


                </div>

              </div>

              <div v-if="uiState.mode=='analysis'">
                <div class="section"> 
                  <div> Arrows </div>                  
                  <div class="selectboxes" v-for="arrow in uiState.arrows" :key="'arr'+arrow"> 
                    <input style="font-size: 3em;" type="checkbox" v-model="arrow.on" />
                    <select v-model="arrow.position[0]">                       
                      <option :key="i" v-for="i in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]" :value="i"> {{i}} </option>
                    </select>
                    <select v-model="arrow.position[2]">                       
                      <option :key="i" v-for="i in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]" :value="i"> {{i}} </option>
                    </select>
                    <select v-model="arrow.color">                       
                      <option :key="i" v-for="i in ['red','green','yellow','black','gray']" :value="i"> {{i}} </option>
                    </select>
                    <input style="width: 1.5em;" type="number" v-model="arrow.index"/>
                  </div>
                </div>
                <div class="section">
                  <div> Dice </div>
                  <div class="selectboxes"> 
                    <select v-model="uiState.checker.current_player">                       
                      <option :key="i" v-for="i in [0,1]" :value="i"> {{i}} </option>
                    </select>
                    <select v-model="uiState.checker.diceRoll[0]">                       
                      <option :key="i" v-for="i in [0,1,2,3,4,5,6]" :value="i"> {{i}} </option>
                    </select>
                    <select v-model="uiState.checker.diceRoll[1]">                       
                      <option :key="i" v-for="i in [0,1,2,3,4,5,6]" :value="i"> {{i}} </option>
                    </select>
                  </div>
                </div>

              </div>

            </div>
        <div style="display:flex;"> 
          <div :style="{ background: theme.background}" style="padding:3em;"> 
            <div v-if="uiState.showBoard" :style="{ background: theme.board.border}" style="padding:1em;"> 
                <AbakMiniBoard                   
                    :checkers_map="checkers_map"
                    :checkers_map_initial="checkers_map"
                    :doubleCube="{ holder: null , value: 1 }"
                    :diceRoll="uiState.checker.diceRoll"
                    :currentPlayer="uiState.checker.current_player"
                    :boardImageSize="boardImageSize"
                    :playerID="1"
                    :checkersColor="0"
                    :objectId = "'boardMain'"
                    :moves ="uiState.arrows"
                    :theme="theme"
                    :selectedChecker="uiState.checker.selected"
                    :showButtons="uiState.mode=='theme'"
                    :showPoints="true"
                    @checker-clicked="checkerClicked($event)"
                    @point-clicked="pointClicked($event)"
                    
                />
            </div>        
            <div v-if="uiState.mode=='theme'" style="padding:2em;" :style="{ background: theme.elements.box1.background, color: theme.elements.box1.color}"> 
                Box1 Example Text 
                <div style="padding:1.5em;" :style="{ background: theme.elements.box2.background, color: theme.elements.box2.color}"> 
                    Box2 Example Text
                </div>
            </div>

            <div v-if="uiState.showChanceSheet"> 
              <div class="chanceSheet" > 
                <div class="chanceHeader" @click="resetSheet()"> - </div>
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(0,'c',0)" > - </button>
                      <button @click="changeSheet(0,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d11.svg')"/>  </div>
                </div>
                  
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(1,'c',0)" > - </button>
                      <button @click="changeSheet(1,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d12.svg')"/>  </div>
                </div>
                  
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(2,'c',0)" > - </button>
                      <button @click="changeSheet(2,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d13.svg')"/>  </div>
                </div>
                  
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(3,'c',0)" > - </button>
                      <button @click="changeSheet(3,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d14.svg')"/>  </div>
                </div>
                  
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(4,'c',0)" > - </button>
                      <button @click="changeSheet(4,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d15.svg')"/>  </div>
                </div>
                  
                <div class="chanceHeader">                    
                  <div> 
                      <button @click="changeSheet(5,'c',0)" > - </button>
                      <button @click="changeSheet(5,'c',1)" > + </button>
                  </div>
                  <div style="margin-top:1em;"><img :src="require('@/assets/img/dados/d16.svg')"/>  </div>
                </div>
                  
                <template v-for="i in [0,1,2,3,4,5]" :key="'dd'+i">
                      <div class="chanceHeader"> <img :src="require('@/assets/img/dados/d1'+(i+1)+'.svg')"/> </div>
                      <div v-for="j in [0,1,2,3,4,5]" :key="'dds'+j">
                        <div 
                          class="chancePoint" 
                          :class="{ on:  1==chanceSheet[i][j] }"
                          @click="chanceSheet[i][j]=(chanceSheet[i][j]+1)%2;if (i!=j) chanceSheet[j][i]=(chanceSheet[j][i]+1)%2;"
                          > 
                          <div v-if="chanceSheet[i][j]==1">
                            <img :src="require('@/assets/img/dados/d1'+(i+1)+'.svg')"/>
                            <img :src="require('@/assets/img/dados/d1'+(j+1)+'.svg')"/>
                          </div>
                          <div v-else style="display: block;">  
                            <div>
                              <img style="width:0.6em;" :src="require('@/assets/img/dados/d1'+(i+1)+'.svg')"/>
                              <img style="width:0.6em;" :src="require('@/assets/img/dados/d1'+(j+1)+'.svg')"/>
                            </div>
                            <div style="font-size: 0.8em;">{{ i!=j ? i+j+2 : (i+1)*4  }}</div>
                          </div>
                        </div>
                      </div>  
                  </template>
              </div>
              <div class="currentChance"> 
                <div> {{ currentChance[0] }} / {{ currentChance[1] }} </div>
                <div> 
                  {{ Math.round(1000*currentChance[0]/currentChance[1])/10 }} %
                </div>
              </div>
          </div>

          </div>        
        </div>
    </div>
</template>
  
<script>

    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";
    import AbakMiniBoard from '../AbakMiniBoard.vue';

    const tablaInicio = [
        { id: 1, column: 24, height: 1, cclass: 5, owner: 0 },
        { id: 2, column: 24, height: 2, cclass: 4, owner: 0 },
        { id: 3, column: 23, height: 1, cclass: 6, owner: 0 },
        { id: 4, column: 27, height: 1, cclass: 1, owner: 0 },

        { id: 5, column: 13, height: 1, cclass: 1, owner: 0 },
        { id: 6, column: 13, height: 2, cclass: 3, owner: 0 },
        { id: 7, column: 0, height: 1, cclass: 1, owner: 0 },
        { id: 8, column: 0, height: 2, cclass: 1, owner: 0 },

        { id: 9, column: 8, height: 1, cclass: 1, owner: 0 },
        { id: 10, column: 8, height: 2, cclass: 3, owner: 0 },
        { id: 11, column: 8, height: 3, cclass: 1, owner: 0 },

        { id: 12, column: 6, height: 1, cclass: 1, owner: 0 },
        { id: 13, column: 6, height: 2, cclass: 1, owner: 0 },
        { id: 14, column: 6, height: 3, cclass: 1, owner: 0 },
        { id: 15, column: 6, height: 4, cclass: 2, owner: 0 },

        { id: 16, column: 1, height: 1, cclass: 5, owner: 1 },
        { id: 17, column: 1, height: 2, cclass: 4, owner: 1 },
        { id: 18, column: 2, height: 1, cclass: 6, owner: 1 },
        { id: 19, column: 26, height: 1, cclass: 1, owner: 1 },

        { id: 20, column: 12, height: 1, cclass: 1, owner: 1 },
        { id: 21, column: 12, height: 2, cclass: 3, owner: 1 },
        { id: 22, column: 25, height: 1, cclass: 1, owner: 1 },
        { id: 23, column: 25, height: 2, cclass: 1, owner: 1 },

        { id: 24, column: 17, height: 1, cclass: 1, owner: 1 },
        { id: 25, column: 17, height: 2, cclass: 3, owner: 1 },
        { id: 26, column: 17, height: 3, cclass: 1, owner: 1 },

        { id: 27, column: 19, height: 1, cclass: 1, owner: 1 },
        { id: 28, column: 19, height: 2, cclass: 1, owner: 1 },
        { id: 29, column: 19, height: 3, cclass: 1, owner: 1 },
        { id: 30, column: 19, height: 4, cclass: 2, owner: 1 }
    ];


var convertCheckersMap = function(chacker_map,skipfirst=false) {
    let arrayHeight = skipfirst ? 15 : 16;
    let shiftLeft = skipfirst ? -1 : 0;
    let table_map = Array(28).fill().map(() => Array(arrayHeight).fill(0));
    let table_ids = Array(28).fill().map(() => Array(arrayHeight).fill(0));
  
    for (var chid in chacker_map) {
      const ownerSign = chacker_map[chid].owner == 1 ? -1 : 1;
      table_map[chacker_map[chid].column][chacker_map[chid].height+shiftLeft] = ownerSign * chacker_map[chid].cclass;
      table_ids[chacker_map[chid].column][chacker_map[chid].height+shiftLeft] = chacker_map[chid].id;
    }
    return [table_map,table_ids];  
}

var ultima_piedra = function(table_map,pos) {
    if (pos<0 || pos>27) return 0;
    var up=0;
    var a;
    for (a=1;a<=15;a++) {
      try {
        if (table_map[pos][a]!=0) {
          up =a;
          }    
      }
      catch {
        console.error;
      }
    }
    return up;
    }


const localTheme =  {
    background: '#142330', // Frame Background
    path: '',
    // Board Elements
    board: {
      background: '#d6c6a7', 
      border: '#593311',
      bar: '#593311',
      points: [
        {
          background: "#5c442c", // Odd placed Point
          border: "#5c442c" 
        },
        {
          background: "#916d49", // Even placed Point
          border: "#916d49"
        }
      ]
    },
    // Dice Set
    dice: [
      // Dice for player 1
      {
        background: "#ede7e1", 
        border: "#333", 
        dot: "#000", 
        dotborder: "#000" 
      },
      // Dice for player 2
      {
        background: "#222", 
        border: "#aaa", 
        dot: "#fff", 
        dotborder: "#000" 
      },
      // Disabled Dice
      {
        background: "grey",
        border: "black",
        dot: "white",
        dotborder: "white",
      },
      // Dice when Invalid move
      {
        background: "#900",
        dot: "#000",
        dotborder: "#000",
        border: "grey"
      }
    ],
    cube: {
      background: "#ede7e1", 
      color: "#2f4858", 
    },
    //Players Checkers Definition
    checkers: [
      //Player 1
      {
        background: "#ede7e1", 
        line: "#777", 
        object1: "#333", 
        object2: "#111", 
        color: "#000", 
      },
      //Player 2
      {
        background: "#111", 
        line: "#666", 
        object1: "#CCC", 
        object2: "#999", 
        color: "#fff" 
      }
    ],
    //Action Buttons
    buttons: {
      default: {
        background: '#4f3924', 
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#200"
      },
      light: {
        background: '#4f3924',
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#000"
      },
      soft: {
        background: '#0081a7', 
        border:"#2f4858", 
        hover:"#fcbf49", 
        off:"#000"
      },
      warning: {
        background:'#fcbf49', 
        border:"#2f4858", 
        hover:"#0081a7", 
        off:"#000"
      }
    },
    // UI Elements
    elements: {
      box1: {
        background:"#361f0a", 
        color:"#000"
      },
      box2: {
        background:"#2ff4858",
        color:"#fff" 
        },
        scroll: {
          bar:"#2f4858", 
          hover:"#0081a7"
        },
        chrono: {
          total: {
            background:"#ffdd94", 
            color:"#000",   
          },
          current: {
            background:"#2f4858", 
            color:"#fff",  
          }
        }
      }
    }

// *************************************************
// Component Definition 
// *************************************************

  export default {
    name: 'BoardDesigner',
    props: {
    },
    components: {
        ColorPicker,
        AbakMiniBoard
    },
    data: function() {
        return {
            boardImageSize:1000,
            showSavedList:false,
            showSavedMaps:false,
            theme:localTheme,
            themeName:"new",
            themeMap:"new",
            uiState: {
                mode:'analysis',
                showChanceSheet:true,
                showBoard:false,
                selected:'default',
                input:'',
                checker: {
                  selected: null,
                  current_player: 0,
                  diceRoll: [4,3],
                },
                arrows:[
                  {on:false,position:[0,0,0],color:'red',dice:6,index:1},
                  {on:false,position:[0,0,0],color:'red',dice:6,index:1},
                  {on:false,position:[0,0,0],color:'red',dice:6,index:1},
                  {on:false,position:[0,0,0],color:'red',dice:6,index:1}
                ]
            },
            checkers_map: tablaInicio,
            checkers_map_initial: tablaInicio,
            chanceSheet: [[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0]]
        }
    },
    methods: {
      copyToClipboard: function() {
        let objStr = JSON.stringify(this.theme, null, 2);
        navigator.clipboard.writeText(objStr).then(function() {
            console.log('Copying to clipboard was successful!');
        }, function(err) {
            console.error('Could not copy text: ', err);
        });        
      },
      saveTheme: function(name) {
        let tlist = JSON.parse(localStorage.getItem("themes"));
        if (!tlist) tlist = {};
        tlist[name] = this.theme;
        localStorage.setItem("themes",JSON.stringify(tlist));
      },
      saveMap: function(name) {
        console.log(name,this.checkers_map);
        let tlist = JSON.parse(localStorage.getItem("maps"));
        if (!tlist) tlist = {};
        tlist[name] = this.checkers_map;
        localStorage.setItem("maps",JSON.stringify(tlist));
      },
      themeList: function() {
        let rlist = localStorage.getItem("themes");
        if (!rlist) return [];
        const list = JSON.parse(rlist);
        return Object.keys(list);
      },
      mapList: function() {
        let rlist = localStorage.getItem("maps");
        if (!rlist) return [];
        const list = JSON.parse(rlist);
        return Object.keys(list);
      },
      loadTheme: function(name) {
        if (confirm("confirm load?")) {
          const tlist = JSON.parse(localStorage.getItem("themes"));
          console.log(tlist);
          this.theme = tlist[name];
          this.themeName = name;
        }

      },
      loadMap: function(name) {
        if (confirm("confirm load?")) {
          const tlist = JSON.parse(localStorage.getItem("maps"));
          console.log(tlist[name]);
          this.checkers_map = tlist[name];
          this.mapName = name;
        }

      },
      clickRandomize: function()       {
        console.log(this.createPalette('#056789'));
        this.randomizeColors(this.theme);
      },
      checkerClicked(event) {
        console.log(event);
        if (this.uiState.checker.selected !== event) {
          this.uiState.checker.selected = event;
        } 
        else {
          this.uiState.checker.selected = null;
        }
      },
      pointClicked(event) {
        console.log("clickedpoint",event);
        let map = convertCheckersMap(this.checkers_map);
        let newh = ultima_piedra(map[0],parseInt(event));
        if (this.uiState.checker.selected ) {
          this.checkers_map[this.uiState.checker.selected-1].height = newh + 1;
          this.checkers_map[this.uiState.checker.selected-1].column = event; 
        }
      },
      changeSheet: function(index,type,action='s') {
        for ( let i=0; i<6; i++ ) {
          for ( let j=0; j<6; j++ ) {
            if ((i==index || j==index) && type=='c') {
              if (action=='s') 
                this.chanceSheet[i][j]=(this.chanceSheet[i][j]+1)%2;
              else 
                this.chanceSheet[i][j]=action;
            }
          }
        }
      },
      resetSheet: function() {
        for ( let i=0; i<6; i++ ) {
          for ( let j=0; j<6; j++ ) {
              this.chanceSheet[i][j]=0;
            }
          }
      },
      hexToHsl:function (hex) {
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);
        r /= 255, g /= 255, b /= 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if(max == min){
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    },

    // Function to convert HSL to hex
     hslToHex: function(h, s, l) {
        let r, g, b;

        if(s == 0){
            r = g = b = l; // achromatic
        } else {
            let hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            }

            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return "#" + [r, g, b].map(x => {
            let hex = Math.round(x * 255).toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        }).join('');
    },

    // Given a base color in hex, create an analogous color palette
    createPalette: function(baseColor) {
        let [h, s, l] = this.hexToHsl(baseColor);
        let palette = [];

        for(let i = -2; i <= 2; i++) {
            // Adjust the hue by 10 degrees
            let newH = h + (i * 10 / 360);
            if(newH < 0) newH += 1;
            if(newH > 1) newH -= 1;

            palette.push(this.hslToHex(newH, s, l));
        }

        return palette;
    },
    randomizeColors: function(obj) {
          // Function to generate random RGB colors
          const randomColor = () => `rgb(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)})`;
          // Function to check if a value is a string representing a color
          const isColor = val => typeof val === 'string' && (val.startsWith('rgb') || val.startsWith('#'));

          for (let key in obj) {
              if (typeof obj[key] === 'object' && obj[key] !== null) {
                  // If the value is an object, recurse
                  this.randomizeColors(obj[key]);
              } else if (isColor(obj[key])) {
                  // If the value is a string representing a color, randomize it
                  obj[key] = randomColor();
              }
          }
      },

      generatePalette: function (rgb) {
        const [r, g, b] = rgb;

        // Helper function to get a random value between a range
        function randomBetween(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }

        // Calculate the contrasting color with a bit of randomness
        const contrastingR = randomBetween(235, 255) - r;
        const contrastingG = randomBetween(235, 255) - g;
        const contrastingB = randomBetween(235, 255) - b;

        // Calculate the in-between color and introduce randomness
        const inBetweenR = Math.round((r + contrastingR) / 2) + randomBetween(-10, 10);
        const inBetweenG = Math.round((g + contrastingG) / 2) + randomBetween(-10, 10);
        const inBetweenB = Math.round((b + contrastingB) / 2) + randomBetween(-10, 10);

        // Ensure values remain in the 0-255 range
        function clamp(value) {
            return Math.max(0, Math.min(255, value));
        }

        return {
            original: [r, g, b],
            contrasting: [clamp(contrastingR), clamp(contrastingG), clamp(contrastingB)],
            inBetween: [clamp(inBetweenR), clamp(inBetweenG), clamp(inBetweenB)]
        };
      },      
    },
    computed: {
      currentChance: function() {
        this.chanceSheet
        const total = this.chanceSheet.reduce((acc, row) => acc + row.reduce((a, b) => a + b, 0), 0);
        const count = this.chanceSheet.length * (this.chanceSheet.length ? this.chanceSheet[0].length : 0);
        return [total , count ];        
      }
    },
    mounted: function() {
            this.$nextTick(function () {
            });
        },
    watch: {
      /*
      "theme.board.bar"(newValue) {
        this.theme.board.border = newValue;
      }
      */
    }  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .section {
    background-color:white;
    color:black;
    margin:1em;
    padding:1em;
    >div:first-child {
      font-size: 2em;
    }
  }

  input,option,select {
    font-size: 1.5em;
  }

  input[type='checkbox'] {
      -webkit-appearance:none;
      width:30px;
      height:30px;
      background:white;
      border-radius:5px;
      border:2px solid #555;
  }

  input[type='checkbox']:checked {
      background: #000;
  }  

  button {
    font-size: 1em;
  }

  .InputFrame {
    position: absolute;
    padding:1em;
    width: 100%;
    height: 100%;
    background:rgb(88, 130, 54);
    z-index:10;
    display: flex;
    flex-direction: column;
  }

  .selectboxes {
    display: flex;
    justify-content: space-around;

    select {
      min-width: 3em;
    }
  }

  .chanceSheet {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* Creates 6 equal columns */
    >div {
      min-width: 3em;
      min-height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:0.2em;
      padding:0.2em;
      text-align: center;
    }
  }

  .chancePoint {
    background-color: rgb(137, 55, 55);
    text-align: center;
    border-radius: 0.5em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;    
    align-items: center;
    >div {
      display: flex;
      text-align: center;
      justify-content: center;
    }
    img {
      width: 1em;
    }
  }
  .on {
      background-color: rgb(13, 78, 13);
    }

.chanceHeader {
  border-radius: 0.5em;
  flex-direction: column;
  >div {
    button {
      background:rgb(87, 87, 49);
      border-radius: 0.5em;
    }
  }
  img {
    width: 1em;
  }
}

.currentChance {
  font-size: 2em;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-left: 10%;
  margin-top:1em;
  background:#000000;
  padding: 0.5em;
  border-radius: 1em;
  >div {
    background: #2a4e6b;
    border-radius: 1em;
    padding:0.5em;
  }
}

</style>
  