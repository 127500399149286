<!-- DateTimeDisplay.vue -->
<template>
    <div class="datetime-display">
      <div class="date">
        <div class="day">{{ formattedDate.day }}</div>
        <div class="month-year">
          <div class="month">{{ formattedDate.month }}</div>
          <div class="year">{{ formattedDate.year }}</div>
        </div>
      </div>
      <div class="time" v-if="showtime">
        <div class="hour">{{ formattedDate.hour }}</div>
        <div class="separator">:</div>
        <div class="minute">{{ formattedDate.minute }}</div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    props: {
      datetime: {
        type: Date,
        required: true
      },
      showtime: {
        type: Boolean,
        required:false,
        default:true
      }
    },
    computed: {
      formattedDate() {
        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return {
          day: this.datetime.getDate(),
          month: monthNames[this.datetime.getMonth()],
          year: this.datetime.getFullYear(),
          hour: this.datetime.getHours().toString().padStart(2, "0"),
          minute: this.datetime.getMinutes().toString().padStart(2, "0")
        };
      }
    }
  }

  </script>
  
  <style scoped>
  .datetime-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
  }
  
  .date {
    display: flex;
    align-items: center;
  }
  
  .day {
    font-size: 2.5em;
    font-weight: bold;
  }
  
  .month-year {
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .month {
    font-size: 1.2em;
  }
  
  .year {
    font-size: 0.9em;
  }
  
  .time {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .separator {
    margin: 0 0.25em;
  }
  </style>
  