<template>

    <div class="todayStats" style="text-align:center;">
        <div class="boxStats" v-if="todayStats.stats.total>0">

            <div> 
                <div class="left right">
                    <div>T</div>
                    <div>{{todayStats.stats.total}}</div>
                </div>
                <div class="left right separator">
                    <div>P</div>
                    <div>{{ Object.values(todayStats.players_number).reduce((a, b) => a + b, 0)  }}</div>
                </div>
                <div class="left separator">
                    <div>30</div>
                    <div>{{todayStats.stats.time['30']}}</div>
                </div>
                <div>
                    <div>60</div>
                    <div>{{todayStats.stats.time['60']}}</div>
                </div>
                <div class="right">
                    <div>360</div>
                    <div>{{todayStats.stats.time['360']}}</div>
                </div>
            </div>

            <div> 
                <div 
                    v-for="p,k,i in todayStats.stats.types" 
                    :class="{ left: i==0, right: i == Object.keys(todayStats.stats.types).length-1}"                    
                    :key="'types'+i">
                    <div>{{k}}</div>
                    <div>{{p}}</div>
                </div>
                <div 
                    v-for="p,k,i in todayStats.stats.opponents" 
                    :class="{ separator: i==0, left: i==0, right: i == Object.keys(todayStats.stats.opponents).length-1}"                    
                    :key="'opp'+i">
                    <div>{{k}}</div>
                    <div>{{p}}</div>
                </div>
            </div>


            <div> 
                <div 
                    v-for="p,k,i in todayStats.stats.level" 
                    :class="{ left: i==0, right: i == Object.keys(todayStats.stats.level).length-1}"                    
                    :key="'types'+i">
                        <div>{{k}}</div>
                        <div>{{p}}</div>
                </div>
                <div 
                    v-for="p,k,i in todayStats.stats.status" 
                    :class="{ separator: i==0, left: i==0, right: i == Object.keys(todayStats.stats.status).length-1}"                    
                    :key="'types'+i">
                    <div>{{k}}</div>
                    <div>{{p}}</div>
                </div>
            </div>
        </div>
        <div v-else>loading stats</div>     
        <div class="statsPlayersControls" v-if="todayStats.stats.total>0">
            <div>Players</div>
            <div class="playersCount"> 
                <div> {{ Object.values(todayStats.players_number).reduce((a, b) => a + b, 0) }} </div>
                <div> 
                    <div><div>N</div> <div>{{ todayStats.players_number.new }} </div></div>
                    <div><div>E</div> <div>{{ todayStats.players_number.engaged }} </div></div>
                    <div><div>O</div> <div>{{ todayStats.players_number.old }} </div></div>

                </div>
            </div>
            <div @click="showPlayers=!showPlayers"> {{ !showPlayers ? "🔽" : "🔼"  }} </div>
        </div>

        <div class="statsPlayersTable" v-if="showPlayers">             
            <DataTable class="display"> 
                <thead> 
                    <tr>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Cy</td>
                        <td>Games</td>
                        <td>ByType</td>
                        <td>Turns</td>
                        <td>Age</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="p,i in todayStats.players" :key="'players'+i" @click="$emit('request-player-info',i)"> 
                        <td>{{i}}</td>
                        <td style="font-size:0.8em;display: flex;">
                            {{p.name}}
                            <img style="width:1.2em;" :src="p.image" />
                            <img style="width:1.3em;" :src="'/img/players/abaker/'+p.abaker+'.svg'">
                        </td>
                        <td>{{countryCodeEmoji(p.country)}}</td>
                        <td>{{p.count.abak + p.count.practice + p.count.classic }}</td>
                        <td>
                            <div style='display:flex;' v-if="p.count.abak>0"><img style='width:1em;' src='/img/symbols/type_abak.svg' /> {{p.count.abak}}</div>
                            <div style='display:flex;' v-if="p.count.practice>0"><img style='width:1em;' src='/img/symbols/type_practice.svg' />{{p.count.practice}}</div>
                            <div style='display:flex;' v-if="p.count.classic>0"><img style='width:1em;' src='/img/symbols/type_classic.svg' />{{p.count.classic}}</div>
                        </td>
                        <td>
                            <div style='display:flex;' v-if="p.count.abak>0"><img style='width:1em;' src='/img/symbols/type_abak.svg' /> {{p.turns.abak}}</div>
                            <div style='display:flex;' v-if="p.count.practice>0"><img style='width:1em;' src='/img/symbols/type_practice.svg' />{{p.turns.practice}}</div>
                            <div style='display:flex;' v-if="p.count.classic>0"><img style='width:1em;' src='/img/symbols/type_classic.svg' />{{p.turns.classic}}</div>
                        </td>
                        <td>{{ p.days }}</td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    </div>
</template>

<script>

const { countryCodeEmoji} = require('country-code-emoji');
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net';
DataTable.use(DataTablesLib);

export default {
		name: 'TodayStats',
        props: ['todayStats'],
        emits: ['request-player-info'],
        components:{DataTable}, 
        data: function() {
            return {
                showPlayers:false
            }
        },
        methods: {
            countryCodeEmoji
        }
	}
</script>

<style lang="scss" scoped>
    .boxStats {
        >div {
            flex:1;
            display: flex;
            justify-content: space-around;                    
        }
        >div>div {
            flex:1;
            display: flex;
            flex-direction: column;
            justify-content: center;                                
        }
        >div>div>div:nth-child(1) {
            background-color:$c11;
            padding:0.2em;            
        }

        >div>div>div:nth-child(2) {
            background-color:$c13;
            padding:0.2em;
            margin-bottom: 0.2em;
        }

        >div .separator {
            margin-left:0.5em;
        }

        .left div:nth-child(1)  {
            border-top-left-radius: 0.4em;
        }
        .left div:nth-child(2)  {
            border-bottom-left-radius: 0.4em;
        }

        .right div:nth-child(1)  {
            border-top-right-radius: 0.4em;
        }
        .right div:nth-child(2)  {
            border-bottom-right-radius: 0.4em;
        }

    }


    .statsPlayers {
        background-color:$c4;
        margin:0.5em;        
        display:flex;
        flex-wrap:wrap;
        padding:0.2em;
        border-radius: 0.4em;
        >div {
            margin:0.2em;
            background-color:$c5;
            border-radius: 0.5em;
            padding:0.5em;
            flex:1;
            >div {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            >div:nth-child(2) {
                padding:0.3em;
                background: $c10;
                >div:first-child {
                    background-color: $c4;
                    border-radius: 0.3em;
                    padding: 0.3em;
                    font-size: 1.2em;
                    margin:0.2em;
                }
                >div:nth-child(2) {  
                    // Abaker Badge
                    >div:last-child {
                        background: #c6bdbd;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        /* padding: 0.2em; */
                        border-radius: 0.5em;
                        border: 0.1em solid black;
                        /* margin: 0.2em; */
                        filter: invert(1);
                    }                  
                    img {
                        font-size: 1.1em;
                        
                    }                    
                }
                >div:nth-child(3) {
                    >div:last-child {
                        background: $c9;
                        padding:0.2em;
                        color:$c0;
                        border-radius: 0.5em;
                    }
                }
            }
        }

    }

    .statsPlayersControls {
        background-color:#5a4f2b;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1em;
        margin-top:0.3em;
        >div:last-child {            
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    .statsPlayersTable {
        
        margin-top:0.5em;

        table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
            padding:0.3em;
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        
    }

    .playersCount {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
        >div:first-child {
            background:red;
            margin-right: 1em;
            padding:0.3em;
            border-radius: 1em;
            min-width: 1.5em;
        }
        >div:last-child {
            align-items: center;
            display: flex;  
            >div {
                align-items: center;
                display: flex;
            }
            >div>div:last-child {
                margin-left:1em;
                background:$c5;
                margin:0.2em;
                padding:0.5em;          
                border-radius: 1em;
                min-width: 1.5em;
            }
        }
    }

    ::v-deep .dataTables_paginate {
        background: red;
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        align-items: center;

        >span {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        >:first-child , >:last-child {
            background:blue;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }

    ::v-deep .dataTables_info {

    }

</style>
