<template>
    <div class="dateTime">
        <div v-if="show.date">{{new Date(date).getFullYear()}}-{{(new Date(date).getMonth()+1).toString().padStart(2,"0")}}-{{(new Date(date).getDate()).toString().padStart(2,"0")}}</div>
        <div v-if="show.hour">{{(  new Date(date).getHours().toString().padStart(2,"0"))}}:{{new Date(date).getMinutes().toString().padStart(2,"0")}}</div>
    </div>
</template>

<script>


	export default {
		name: 'DateTime',
        props: ['date','show'],
        data: function() {
            return {
            }
        },
        methods: {
        }

	}
</script>

<style scoped lang="scss">

 .dateTime {

 }

</style>
