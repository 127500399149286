<template>
    
        <div class='contenedor-tabla' 
            @click="clickBoard()"            
            :style="{ width: anchoCanvas + 'px', height: altoCanvas + 'px' , background: theme.board.background }"
            >
            
            <!-- *********************************  Columnas de la tabla Grande  ***************************************************-->
            <PointSVG :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'25'" :anchoColumna="anchoColumna"
                   :left ="0" :top="0" inverted=false
                   :showPoints ="false"
                   :colors="theme.board"


            />

            <PointSVG :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'0'" :anchoColumna="anchoColumna"
                   :left ="0" :top="50" inverted=false
                   :showPoints ="false"
                   :colors="theme.board"

            />

            <PointSVG :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'26'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="50" inverted=false
                    :showPoints ="showPoints"
                    :colors="theme.board"

                @click="$emit('point-clicked','26')"

            />

            <PointSVG :type="'c'" :typeColor="null" 
                    :playerID ="getPlayerID"
                   :index="'27'" :anchoColumna="anchoColumna"
                   :left ="(anchoColumna+separacionColumna2)*7" :top="0" inverted=false
                    :showPoints ="showPoints"
                    :colors="theme.board"

                    @click="$emit('point-clicked','27')"
            />

            <PointSVG :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="'0'"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="0" inverted=false
                    :showPoints ="showPoints"
                    :colors="theme.board"

                    @click="$emit('point-clicked','0')"

            />

            <PointSVG :type="'c'" :typeColor="null" 
                   :playerID ="getPlayerID"
                   :index="'25'"
                   :left ="(anchoColumna+separacionColumna2)*14" :top="50" inverted=false
                    :showPoints ="showPoints"
                    :colors="theme.board"

                    @click="$emit('point-clicked','25')"
            />

            <PointSVG 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="19-(i+6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints ="showPoints"
                :direction="1"
                :colors="theme.board"

                @click="$emit('point-clicked',19-(i+6))"


            />

            <PointSVG 
                v-for="i in [1,2,3,4,5,6]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="i+12" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints ="showPoints"
                :direction="1"
                :colors="theme.board"

                @click="$emit('point-clicked',i+12)"

            />

            <PointSVG 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="(i+1)%2+1"                 
                :index="8-(i-6)" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="0" :inverted="true"
                :showPoints ="showPoints"
                :direction="1"
                :colors="theme.board"

                @click="$emit('point-clicked',8-(i-6))"

            />

            <PointSVG 
                v-for="i in [8,9,10,11,12,13]" :key ="'pos'+i"
                :playerID ="getPlayerID"                
                :type="'0'" :typeColor="i%2+1"                 
                :index="i+11" :anchoColumna="anchoColumna"
                :left ="(anchoColumna+separacionColumna2)*i" :top="50" :inverted="false"
                :showPoints ="showPoints"
                :direction="1"
                :colors="theme.board"

                @click="$emit('point-clicked',i+11)"
            />


            <!--
            <svg v-for="(m,i) in moves" :key="'flecha'+i"
                class="SVGFlecha"                                
            >
                <line 
                        :x1="(x([m[0],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                        :y1="(maxTop - y([m[0],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                        :x2="(x([m[2],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                        :y2="(maxTop - y([m[2],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                        style="stroke:#77cc0999;stroke-width:2%" />
                <circle
                        :cx="(x([m[2],m[1]+1],1)*anchoColumna+anchoColumna/2)*unidadX+'px'" 
                        :cy="(maxTop - y([m[2],m[1]+2],playerID)*anchoColumna*unidadX)+'px'"  
                        :r="'1%'"
                        style="stroke:rgb(255, 251, 0);stroke-width:3%" />
            </svg>
            -->

            <!-- *********************************  Piedras  ***************************************************-->
            
            <CheckerComp 
                v-for="checker in getCheckersMap"
                    :key ="objectId+'chkmini_'+checker.id"
                    :chkId="objectId+'chkmini_'+checker.id"
                    
                    :position="[checker.column,checker.height]"
                    :cclass="checker.cclass"
                    :owner="checker.owner"
                    
                    :direction="'1'"
                    :checkersColor="checkersColor"

                    :tips ="null"
                    
                    :colWidth=anchoColumna
                    :colHeight=altoPosicion
                    :XunitSize=unidadX
                    :YunitSize=unidadY
                    :maxTop=maxTop
                    
                    :playerID="getPlayerID"
                    
                    :starting="true"
                    :selectedChecker="selectedChecker==checker.id"
                    :forcePoistion="true"

                    :colors="theme.checkers"

                @click="$emit('checker-clicked',checker.id)"
            />

            <div v-if="showButtons"
                class="diceRoll"
            >
                <DiceSVG :imageSize="boardImageSize/20" :dClass="0" :dNumber="diceRoll[0]" :colorClases="theme.dice" />
                <DiceSVG :imageSize="boardImageSize/20" :dClass="1" :dNumber="diceRoll[1]" :colorClases="theme.dice" />
                <DiceSVG :imageSize="boardImageSize/20" :dClass="2" :dNumber="diceRoll[0]" :colorClases="theme.dice" />
                <DiceSVG :imageSize="boardImageSize/20" :dClass="3" :dNumber="diceRoll[1]" :colorClases="theme.dice" />
            </div>
            <div v-else class="diceRoll2"> 
                <DiceSVG v-if="diceRoll[0]>0" :imageSize="boardImageSize/20" :dClass="currentPlayer" :dNumber="diceRoll[0]" :colorClases="theme.dice" />
                <DiceSVG v-if="diceRoll[1]>0" :imageSize="boardImageSize/20" :dClass="currentPlayer" :dNumber="diceRoll[1]" :colorClases="theme.dice" />
            </div>

            <CubeSVG v-if="doubleCube"
                :key="'CubeButton'"
                :id="'CubeButton'"
                :doubleCube='doubleCube'
                :PlayerID="playerID"
                :direction="1"
                :colWidth="anchoColumna*unidadX"
                :colors="theme.cube"
            />



            <MoveArrows v-for="(c,i) in moves"
                :id="'MoverArrow'+i"
                :playerID="playerID"
                :key="'MoverArrow'+i"
                :markData="c"
                :XunitSize="unidadX"
                :YunitSize="unidadY"
                :direction="1"
                :colWidth="anchoColumna"
                :maxTop="maxTop"
            />
            
            <div class="gamebuttons" v-if="showButtons">
                <div :style="{ 
                    background: theme.buttons.default.background , 
                    borderColor: theme.buttons.default.border,
                    }"> 
                    <img :src="require('@/assets/img/buttons/roll.svg')" />
                </div> 
                <div :style="{ 
                    background: theme.buttons.light.background , 
                    borderColor: theme.buttons.light.border,
                    }"> 
                    <img :src="require('@/assets/img/buttons/roll.svg')" />
                </div> 
                <div :style="{ 
                    background: theme.buttons.light.off , 
                    borderColor: theme.buttons.light.border,
                    }"> 
                    <img :src="require('@/assets/img/buttons/roll.svg')" />
                </div> 
                <div :style="{ 
                    background: theme.buttons.soft.background , 
                    borderColor: theme.buttons.soft.border,
                    }"> 
                    <img :src="require('@/assets/img/buttons/roll.svg')" />
                </div> 
                <div :style="{ 
                    background: theme.buttons.warning.background , 
                    borderColor: theme.buttons.warning.border,
                    }"> 
                    <img :src="require('@/assets/img/buttons/roll.svg')" />
                </div> 
            </div>
    </div>                  

</template>

<script>
    import CheckerComp from "./elements/CheckerComp.vue";
    import CubeSVG from "./elements/CubeSVG.vue";
    import PointSVG from "./elements/PointSVG.vue";
    import DiceSVG from './elements/DiceSVG.vue';
    import MoveArrows from './elements/MoveArrows.vue'
    var posHelper = require('../assets/js/positionHelpers');

	export default {
		name: 'AbakMiniBoard',
        emits: [ 'checker-clicked' ,'point-clicked' ],
        components: {
            CheckerComp, 
            PointSVG,
            CubeSVG,
            DiceSVG,
            MoveArrows
            },
        props: {
            objectId: String,
            boardImageSize: Number,
            checkers_map: Object,
            checkers_map_initial: Object,
            playerID: Number,
            checkersColor: Number,
            moves: Object,
            diceRoll:Object,
            currentPlayer:Number,
            doubleCube:Object,
            theme:Object,
            showButtons: Boolean,
            selectedChecker: Number,
            showPoints:Boolean
            },
        data: function() {
            return {   
                tabla: {direccion:1,inicio:3} , 
                checkerReload : false,           
                showInitial: false                
            }
        },

        computed: {
            getCheckersMap: function() {
                if (
                    this.showInitial
                    && typeof this.checkers_map_initial !="undefined" 
                    && this.checkers_map_initial) {
                    return this.checkers_map_initial;                                             
                }
                return this.checkers_map;                                         
            },
            getPlayerID: function() {
                return this.playerID;
            },
            altoCanvas: function() {
                return this.boardImageSize*0.6;
            },            
            anchoCanvas: function() {
                return this.boardImageSize*0.75;
            },
            unidadX: function() {
                return this.boardImageSize*0.75/100;
            },            
            unidadY: function() {
                return this.boardImageSize*0.5/100;
            },
            alturaDado: function() {
                let or = this.tabla.orientacion;
                if (or>0) return "20%";
                else return "60%";                
            },
            fontSize: function() {
                return 1.5*this.boardImageSize/10;
            },
            strokeWidth: function() {
                return this.boardImageSize/100;
            },
            maxTop: function() {
                return this.boardImageSize*0.6;
            },
            anchoColumna: function() {                 
                if (this.tabla.inicio ==3) return 6.67;
                return 12.5
            },                
            separacionColumna: function() { 
                if (this.tabla.inicio ==3) return 0.75;
                return 1.5
            },
            separacionColumna2: function() {                 
                return 0
            },
            altoPosicion: function() { 
                return 50;                
            },
            getImageSize: function() {
                return this.anchoColumna*this.unidadX;
            },
        },
        methods: {	
            x: posHelper.x,
            y: posHelper.y,
            clickBoard: function () {
                this.showInitial = ! this.showInitial;
            }
        }     
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.contenedor-tabla {
        position:relative;
        background-color:$c7;
    }    

    .pos1, .pos2 , .soldado , .dado {
        position:absolute;
    }
              
    
    .pointsBox1,.pointsBox0 {
        position:absolute;
        width:6.55%;
        height:10%;        
        display:flex;
        flex-direction: column;
        align-items: center;

        div {
            width:100%;
            text-align: center;
        }
        .distanceBox {
            flex-grow: 1;            
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:0.7em;
            background:#33333366;
        }


    }

    .pointsBox0 {
        background:$checkerblack-background;
        color:$checkerblack-color;
        left:0;
        top:5%;
    }

    .pointsBox1 {
        background:$checkerwhite-background;
        color:$checkerwhite-color;
        left:0;
        top:85%;
    }

    .SVGFlecha {
        position:absolute;
        height: 100%;
        width: 100%;
        z-index: 1000;
    }

    .diceRoll {
            position: absolute;
            left:38%;
            top:50%;
            display: flex;
            font-size: 2em;
            img {
                width: 15%;
                height: 15%;
            }
    }

    .diceRoll2 {
        position: absolute;
            left:43%;
            top:45%;
            display: flex;
            font-size: 2em;
            img {
                width: 15%;
                height: 15%;
            }        
    }

    .gamebuttons {
        display: flex;
        position: absolute;
        left:v-bind('boardImageSize/6.5+"px"');;
        top:v-bind('boardImageSize/4.7+"px"');;
        img {
            width: v-bind('boardImageSize/20+"px"');
        }


        >div {
            width: v-bind('boardImageSize/20+"px"');
            height: v-bind('boardImageSize/20+"px"');
            margin:v-bind('boardImageSize/100+"px"');
            border-radius: 50%;
            padding:v-bind('boardImageSize/100+"px"');;
            display: flex;
            box-shadow:0px 0px 0.3em #000000;
            justify-content: center;
            align-items: center;

        }
    }

</style>
