<template>
    <div> 
        <div class="filters"> 
            <div> Friendship </div>
            <div @click="getFriendship()"> Fetch </div>
        </div>
        <div v-if="uiState.loading"> loading </div>
        <div v-else> 
            <table>
                <thead> 
                    <td> p1 </td>
                    <td> p1 </td>
                    <td> date </td>
                    <td> st </td>
                    <td> A </td>
                    <td> p1A </td>
                    <td> p2A </td>
                </thead>
                <tbody> 
                    <tr v-for="f,i in friendship.raw" :key="i"> 
                        <td> {{ countryCodeEmoji(f.p1country) }} {{ f.p1name }}</td>
                        <td> {{ countryCodeEmoji(f.p2country) }} {{ f.p2name }}</td>
                        <td> <DateTimeDisplay :datetime="new Date(f.date_requested)" :showtime="false"/></td>
                        <td>{{ f.state[0] }}</td>
                        <td>{{ f.active }}</td>
                        <td>{{ f.player1_active }}</td>
                        <td>{{ f.player2_active }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    import DateTimeDisplay from './DateTimeDisplay.vue';
    const { countryCodeEmoji} = require('country-code-emoji');
    const { APIURL,APIPORT,WEBPROTOCOL } = require('../../assets/js/enviroment.js');

	export default {
		name: 'PlayersFriendship',
        props: ['adminRights'],
        components: { 
            DateTimeDisplay 
        },
        data: function() {
            return {
                uiState: {
                    loading: false,
                    mode:'stats'
                },
                friendship: {
                    raw: [],
                },
                filters: {
                }
            }
        },
        methods: {
            countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            filterData: function() {
                console.log("filter");
            },
            getFriendship: function() {
                this.uiState.loading = true;
                let data = {
                }
                this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/admin/friendship/get/`,data,this.getAuthHeader()).then((response) => {
                        this.uiState.loading = false;
                        this.friendship.raw = response.data;
                        this.filterData();
                    }
                    ).catch((error) => {
                        this.uiState.loading = false;
                        console.log(error);
                        }
                    );
            }
        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }

	}
</script>

<style lang="scss" scoped>

        table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        

        .detail {
            display: flex;
            justify-content: space-around;
            position:fixed;
            background:$c6;
            width:100%;
            height: 5em;
            padding:0.3em;
            >div {
                background:$c5;
                padding:1em;
            }
        }


        .todayGames {
        text-align: center;  
        width: 100%;      
    }

    .todayGame {
        background-color:$c6;
        margin:0.5em;        
        border-radius: 0.5em;
        >div {
            display:flex;
            align-items:center;
            justify-content: space-around;
        }
    }

    .matchInfo {
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-direction: column;
        >div {
            display:flex;            
        }

        >div:nth-child(2)>div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
        }
        >div:nth-child(3){
            >div {
            padding:0.3em 0.34em;
            background-color: $c7;
            margin:0em;
            }
            :nth-child(1) {
                background: $c0;
            }
            :nth-child(2) {
                background: $checkerwhite;
            }
            :nth-child(3) {
                background: $checkerblack;
            }
        }
    }

    .dateContainer {
        text-align:center;
    }

    .detailHide {
        display:none;
    }

    .gameOverView1 {
        display:flex;
        justify-content: space-around;
        >div {
            margin:0.3em;
            background-color: $c8;
            padding:0.3em;
        }
        text-align: center;
    }

    .statsContainer {

        .level1Container {
            >div.title {
                background:$c9;
            }
            background:$c6;
            >div:last-child {
                display: flex;
                flex-wrap: wrap;
            }
            margin:0.5em;
        }

        .level2Container {
            margin:0.5em;
            background:$c5;
            >div:last-child {
                display: flex;
            }
        }

        .valueContainer {
            background:$c4;
            margin:0.2em;
            padding: 0.3em;
            >div:last-child {
                background:$c0;
                padding: 0.3em;
            }
        }

    }

    .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

   .cpuContainer {
       background-color:$c11;
       border-radius: 0.5em;
       padding: 1em;

       >div:nth-child(2) {
           background-color: $c3;
           border-radius: 0.3em;
           color:black;
       }
   }

   .statsPlayersControls {
        background-color:#5a4f2b;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 1em;
        margin-top:0.3em;
        :last-child {            
            padding:0.5em;
            border-radius: 0.5em;
        }
    }

    .filters {
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        box-sizing: border-box;
        flex-wrap: wrap;
        >div {
            background: $c8;
            padding: 0.3em;
        }
    }

    .messageDelivery {
        >div {
            >div {
                padding:0.5em;
                flex-grow: 0.3;
            }
            >div:last-child {
                border-radius: 0.3em;
                background: red;
                >div {
                    display: flex;
                    justify-content: space-around;
                    >div:first-child {
                        background:red;
                    }
                }
            }
        }
    }


</style>
