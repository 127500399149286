import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueCookieNext } from 'vue-cookie-next'

//import * as Vue from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'


const app = createApp(App);
app.use(router);
app.use(VueAxios, axios);
app.use(VueCookieNext);
app.mount('#app');
