<template>
    <div class="menu">        
        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('status');"> Status </div>
            <div v-if="uiState.menuStatus.status" class="SubMenu">                 
                <a href="/status"> Status </a>
                <a href="/status/logs"> Logs </a>
            </div>
        </div>

        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('current');"> Online </div>
            <div v-if="uiState.menuStatus.current" class="SubMenu">                 
                <a href="/online"> Combined </a>       
                <a href="/games/current?active=1"> Now </a>
                <a href="/online/historic"> Historic </a>                
            </div>
        </div>

        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('stats');"> Games </div>
            <div v-if="uiState.menuStatus.stats" class="SubMenu"> 
                <a href="/games/stats?daymin=0&daymax=1"> Today </a>
                <a href="/games/stats?daymin=1&daymax=2"> Yesterday </a>
                <a href="/games/stats?daymin=0&daymax=7"> Week </a>
                <a href="/games/current?active=0"> Created </a>
            </div>
        </div>

        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('usage');"> Usage </div>
            <div v-if="uiState.menuStatus.usage" class="SubMenu"> 
                <a href="/games/historic"> Game </a>
                <a href="/ai/usage"> AI </a>
            </div>
        </div>


        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('players');"> Players </div>
            <div v-if="uiState.menuStatus.players" class="SubMenu"> 
                <a href="/players/new"> New </a>
                <a href="/players/engaged"> Engaged </a>
                <a href="/players/info"> Player </a>
                <a href="/players/preferences"> Preferences </a>
                <a href="/players/list"> List </a>
            </div>
        </div>

        <div style="position:relative">
            <div  class="MenuItemMore" @click="menuOpenClicked('messages');"> Messages </div>
            <div v-if="uiState.menuStatus.messages" class="SubMenu"> 
                <a href="/messages/list"> List </a>
                <a href="/messages/create"> Create </a>
                <a href="/messages/send"> Send </a>
            </div>
        </div>

        <div style="position:relative"> 
            <div  class="MenuItemMore" @click="menuOpenClicked('interactions');"> Interactions </div>
            <div v-if="uiState.menuStatus.interactions" class="SubMenu"> 
                <a href="/interactions/invitations"> Invitations </a>
                <a href="/interactions/chat"> Chats </a>
                <a href="/interactions/friendship"> Friendship </a>
            </div>
        </div>

        <a href="/designer"> Designer </a>   

    </div>
</template>
<script> 
    export default {
		name: 'NavitationMenu',
        methods: {
            menuOpenClicked: function(event) {
                this.closeAllMenus();                
                this.uiState.menuStatus[event] = !this.uiState.menuStatus[event];
            },
            closeAllMenus: function() {
                Object.keys(this.uiState.menuStatus).forEach(key => this.uiState.menuStatus[key] = false);
            }
        },
        data: function() {
            return {
                uiState: {
                    menuStatus: {
                        status:false,
                        current:false,
                        stats:false,
                        players:false,
                        interactions:false,
                        usage:false,
                        messages:false,
                    }
                }
            }

        }
    }

</script>

<style lang="scss"> 
    .menu {
            display:flex;
            justify-content: center;
            flex-wrap: wrap;
            font-size:0.85em;
            position:relative;

        >div {
            background-color:$c4;
            margin:0.3em;
            padding: 0.3em;
            border-radius: 0.5em;

            >div {
                cursor:pointer;
                display: flex;
                flex-direction: column;
                >a {
                    text-align: center;
                    background-color:$c4;
                    margin:0.1em;
                    padding: 0.3em;
                    border-radius: 0.5em;
                    color:white;
                    text-decoration: none;
                }

            }

        }

        >a {
            background-color:$c4;
            display: block;
            margin:0.3em;
            padding: 0.3em;
            border-radius: 0.5em;
            color:white;
            text-decoration: none;
        }

    }

    .SubMenu {
        position: absolute;
        z-index:10000;
        background-color:$c11;
        border-radius:0.7em;
        padding: 0.4em;
        >div {
            cursor: pointer;
            padding: 0.5em;
            background-color:$c8;
            margin:0.3em;
            border-radius: 0.3em;
            min-width:5em;
        }
    }
</style>