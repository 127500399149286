<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->
            <ChatsView v-if="chats" :chats="chats" />        
    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    //import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';   
    import ChatsView from "@/components/admin/ChatsView.vue";

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminFriendship',
        props: [],
        components: {
            //DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            ChatsView
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                chats:null,
                players: {
                    list: [1,2,3,4]
                },
                filters: {
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                }
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getChats: function() {
                    this.uiState.loading = true;
                    this.uiState.tabShow = "chats";
                    let postData = { maxdays: 10, mindays:0 };
                    this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/chats/get`,postData,this.getAuthHeader()).then((response) => {
                        this.serverTimeDiff = Date.now() - response.data.now;
                        console.log(response.data);
                        try {
                            this.chats = response.data;
                        }
                        catch {
                            console.log("failed to parse");
                            this.chats= [];
                        }
                        this.uiState.loading = false;
                        }).catch((error) => {
                            this.uiState.loading = false;
                            this.uiState.error = { on:true, type : error.message };setTimeout(()=>this.uiState.error={on:false,type:""},3000);
                            console.error(error);
                            }
                        );
            }, 
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    this.getChats();
                    }

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        }
    }
</script>

<style>
</style>