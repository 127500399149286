<template>
<div style="
    background:#06160f;
    margin:0px;
    min-height:100em;
    ">
<!-- *********************************************************************************** -->    
<!-- *********************** Start Screen Cookie       ********************************* -->    
<!-- *********************************************************************************** -->    
    <NavigationMenu></NavigationMenu>
    <div class="tab">
        <h1>Abak Dashboard</h1>
        <div v-if="cookieSet">
            <h3>Admin Credential is set.</h3>
            <button @click="adminRights='';cookieSet=false"> Change </button>            

            <div> 
                <h3>Quick Navigation </h3>
                <button @click="redirectToUrl('/games/current?active=1')"> Now </button>            
                <button @click="redirectToUrl('/online/historic')"> Historic </button>            
            </div>

        </div>
        <div v-else>            
            <h3>Set Admin Credential. </h3>
            <div style="font-size:1.2em">
            🍪 <input v-model="adminRights"  type="password">
            <input type="button" value="Take" @click="setCookie();">
        </div>
        </div>
    </div>


</div>

</template>

<script>
    

    import NavigationMenu from '@/components/NavigationMenu.vue';

	export default {
		name: 'AdminMain',
        props: [],
        components: {
            NavigationMenu
        },
        data: function() {
            return {
                adminRights: "",                
                cookieSet: false,                
                chartData: {
                    labels: [ 'January', 'February', 'March' ],
                    datasets: [ { data: [40, 20, 12] } , { data2: [40, 20, 12] } ,  ]
                },
                chartOptions: {
                    responsive: true
                },
                chartOptionsStacked: {
                    responsive: true,
                    scales: {
                        y1: {
                            beginAtZero:true,                            
                            position:"left"
                        },
                        y2: {
                            beginAtZero:true,                            
                            position:"right"
                        },
                        
                    }
                },
                serverTimeDiff:0,                
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {	
            setCookie: function() {
                localStorage.setItem("adminAuthToken", this.adminRights);                  
                this.cookieSet = true;
            },
            redirectToUrl: function(url) {
                this.$router.push(url);
            }            
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }

            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .tab {
        //border: 1px solid white;
        padding:0.5em;
        background-color: $c7;
        overflow:auto;
    }

    .gamesContainer {
        display: flex;
        flex-wrap: wrap;        
    }

   .gameContainer {
       background: $c9;
       display: flex;
       flex-direction: column;
       margin:0.2em;
       border-radius: 0.5em;

   }

    .twoPlayers {
    }

   .typeContainer {       
       display:flex;
       justify-content: space-around;
       >div {
           margin: 0.2em 0.2em;
           background: black;
           padding:0.2em 0.5em;           
           border-radius: 0.3em;
       }
   }

   .datesContainer {
       display:flex;
       justify-content: space-around;
       >div {
           margin: 0.2em 0.2em;
           background: black;
           padding:0.2em 0.5em;           
           border-radius: 0.3em;
       }
   }

   .playerContainer {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img {
           width:2em;
       }
       background:$c4;
       border-radius: 0.5em;
       padding:0.5em;
       margin:0.5em;
   }

     .connected {
         background:green;
         width: 1em;
         display: flex;
         border-radius: 0.5em;
         height: 1em;
     }
   .notConnected {
       background: red;
   }

   .menu {
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size:0.85em;
        position:relative;

       >div {
           background-color:$c4;
           margin:0.3em;
           padding: 0.3em;
           border-radius: 0.5em;
       }
   }

   .SubMenu {
    position: absolute;
    z-index:10000;
    background-color:$c11;
    border-radius:0.7em;
    padding: 0.7em;
    >div {
        cursor: pointer;
        padding: 0.5em;
        background-color:$c8;
        margin:0.3em;
        border-radius: 0.3em;
        min-width:5em;
    }
   }

   .notPlayed {
    background:rgb(51, 6, 6) !important;
   }

   .hasFinished {
    background: rgb(7, 34, 7) !important;
   }

   .onlyUnfinished {
    background: rgb(53, 37, 11) !important;
   }


    .player {
        margin:0.5em;
        background-color: $c6;
        min-width: 7em;      
        border-radius: 0.5em;                  
        .playername {
            border-radius:0.5em 0.5em 0 0;
            background-color:$c8;
            text-align:center;
            padding:0.3em;            
        }
        >div:nth-child(2) {
            padding-top:0.5em;            
        }        
        >div:nth-child(4) {
            padding:1em;            
        }
    }

    .boxWinner {
        border:2px solid rgb(224, 194, 24);
    }

    .playerHead {
        display:flex;
        justify-content: space-around;
    }

    .players {
        display:flex;
        flex-wrap: wrap;
        margin:0;
    }
    

    .detailHide {
        display:none;
    }

    .button {
        background-color:$c11;
        padding:0.3em;
        border-radius: 0.3em;
        cursor:pointer;
    }

    .statusItems {
        display:flex;
        >div:nth-child(1) {
            background-color:$c8;
        }
        >div {
            background-color:$c5;
            padding:0.3em;
            border-radius: 0.3em;
            margin:0.2em;
        }
        span {
            background-color:$c3;
            padding: 0.05em 0.5em;
            font-size:0.8em;
        }
    }

    .playerStats, .playerStatsGames {
        .gamesList {
        }

        .dayStatList {
            display:grid;
            grid-template-columns: 20% repeat(8,10%);
        }

        .cwinner {
            background: green;
        }

        .clooser {
            background: red;
        }

    }
    
    .active {
     background:$c5 !important;   
    }

    .playerInfoContainer {
            display:flex;
            background-color:rgb(3 41 8);
            justify-content: space-around;
            margin: 0.3em;
            padding: 0.3em;
            border-radius: 0.3em;
            align-items: center;            
    }

    .engaged_table {
        width: 100%;
        background:darken($c4,5%);
        border-radius: 0.5em;
    }

    .engaged_table thead {
        background:$c8;
        padding:0.2em;
    }
    
    .engaged_table td {
        padding:0.3em;
    }

    .engaged_table tbody tr:nth-child(even) {
        background:darken($c4,10%);
    }


    table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

    thead {
        padding:0.2em;
        td {
            border-bottom: 1px solid black;
            padding: 0.3em;
            margin: 0.3em;
            background: none;
        }
    }
    td {
    }

    .errorMessage {
        background:red;
        text-align: center;
        padding:0.3em;
        margin:1em;
    }

    ::v-deep .dataTables_paginate {
        background: red;
        display: flex;
        justify-content: space-around;
        padding:0.5em;
        align-items: center;

        >span {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        >:first-child , >:last-child {
            background:blue;
            padding:0.3em;
            border-radius: 0.3em;
        }
    }

    ::v-deep .dataTables_info {

    }

</style>



