<template>
    <div class="point"
     :style="{ 
         width : anchoColumnaC + '%', 
         left: leftC + '%' ,
         top: topC+ '%',
         height: altoColumnaC + '%'}"
     >
     <svg 
        id="svg7539" 
        viewBox="0 0 52 250"
        :class="{ cinverted: inverted }"
        preserveAspectRatio="none"
        style="width:100%;height: 100%;"
     >

        <g v-if="type!='c'" id="g7296" transform="matrix(1 0 0 .97225 26 150.83)">
            <path id="path7298" :fill="vcolors.points[vTypeColor].border" d="m0-98-25 200h50l-25-200z"/>
            <path id="path7300" :fill="vcolors.points[vTypeColor].background" d="m-20.888 102h41.776l-20.888-167-20.888 167z"/>
            <path id="path7302" :fill="vcolors.points[vTypeColor].border" d="m0-102 4 8.043-4 4.957-4-4.957 4-8.043z"/>
        </g>

        <g v-if="type=='c'" id="layer1">
            <rect id="rect3885-3" height="250" width="52" y="0" x="0" :fill="vcolors.bar" style="fill-opacity:1" />
        </g>


    </svg>

        <div v-if="index!='' && showPoints" :style="{ top: labelTop } " 
            :class="{ cbar : index > 25,
                      cgoal: index == 0 || index == 25
                    }"
            class="indexLabel">{{vindex}}</div>   
    </div>
</template>

<script>

    //import BOARD_BAR1 from "../../js/constants.js";
    //import BOARD_BAR2 from "../../js/constants.js";

	export default {
		name: 'PointSVG',
        props: [                
                'type',
                'typeColor',
                'index',
                'anchoColumna',
                'left',
                'top',
                'inverted',
                'playerID',
                'showPoints',
                'direction',
                'colors'                
               ],
        data: function() {
            return {
            }
        },
        computed: {
            topC: function() {
                if (this.type!="c") return this.top;
                if (this.top==0) return -0.1;
                return this.top;
            },
            leftC: function() {
                if (this.type!="c") return this.left;
                if (this.left==0) return -0.1;
                return this.left;
            },
            altoColumnaC: function() {
               if (this.type=="c") return "50.5";
               return "50";
            },
            anchoColumnaC: function() {
               if (this.type=="c") return this.anchoColumna*1.01;
               return this.anchoColumna;
            },
            labelTop: function() {
                if (this.top==50) return "20%"
                return "75%"
            },
            vindex: function() {
                if (!this.playerID || this.playerID==='0') {
                    if (this.index == 26) return "B0";
                    if (this.index == 0) return 0;
                    if (this.index == 25) return 25;
                    if (this.index == 27) return "B1";
                    if (this.direction == 1)
                        return this.index;
                    else {
                        if (this.index>12) return 37-this.index;
                        else return 13-this.index;
                    }
                }                    
                else {
                    if (this.index == 26) return "B1";
                    if (this.index == 27) return "B0";
                    if (this.index == 0) return 25;
                    if (this.index == 25) return 0;
                    if (this.direction == 1)
                        return 25-this.index;
                    else {
                        if (this.index>12) return this.index-12;
                        else return this.index+12;
                    }
                }                
            },
            vTypeColor: function() {                
                if (!this.playerID || this.playerID==='0')
                    return this.typeColor-1;
                else return (this.typeColor)%2; //-1+1
            },
        vcolors: function() {
            if (this.colors) return this.colors;
            return {
                bar:"fff",
                points: [
                    {
                        background: "#ac784a",
                        border: "#230d07"
                    },
                    {
                        background: "#a03639",
                        border: "#230d07"
                    }
                ]
            }
        }
            
            
        },
        methods: {	

        },
        watch: {
        },
        mounted: function() {
            this.$nextTick(function () {
            });
        }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .point {
        position:absolute;        
    }

    .point img { 
        width: 100%;
        height: 100%;
    }

    .cinverted {
        transform:scaleY(-1);
    }

    .indexLabel {
        font-size:0.7em;
        position:absolute;
        top:90%;
        padding: 0.3em 0.4em;
        width:60%;
        margin-left:20%;
        text-align: center;        
        background:black;
        box-sizing: border-box;
        border-radius: 0.5em;

    }

    .cbar {
        background: red;
    }

    .cgoal {
        background:green;
    }

</style>
