<template>
    <div> 
        <div><NavigationMenu /></div> 
        <!--UserAgent :useragent="'UserAgentString'"/>
        <DateTimeDisplay :datetime="new Date()" :showtime="true"/>
        <DataTable></DataTable -->

        <div style="display: flex;justify-content: space-around;margin:0.5em 0;background: #555555;padding: 0.3em;border-radius: 0.3em;"> 
            <select v-model="filters.action"> 
                <option value="list"> List </option>
                <option value="delivery"> Delivery </option>
            </select>
            <button @click="getMessages()"> Fetch </button>
            <input type="text" size="3" v-model="filters.message_id" />
            <div style="flex:0.7;display: flex;justify-content: center;"> <div v-if="filters.action=='delivery'"> Read: {{ read_messages }} </div> </div>
            <button @click="$router.push('/messages/create')"> Create Message</button>
        </div>

        
        <div v-if="uiState.loading" class="message"> Loading </div>


        <div v-if="filters.action=='list'">
            <table> 
                <tbody>
                    <tr v-for="m,k in messages" :key="'m_'+k"> 
                        <td>{{ m.mid }}</td>
                        <td>{{ m.mname }}</td>
                        <td>{{ m.mdescription }}</td>
                        <td><button @click="$router.push('/messages/send?mid='+m.mid)"> Send </button> </td>
                        <td><button @click="$router.push({path:'/messages/list', query:{action:'delivery',mid:m.mid}})"> Delivery </button> </td>
                    </tr>
                </tbody>
            </table> 
        </div>

        <div v-if="filters.action=='delivery'">
            <table> 
                <thead> 
                    <tr>
                        <th>Player</th>
                        <th>Sent </th>
                        <th>Read</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr v-for="m,i in messages" :key="'md_'+i">
                        <td> 
                            <div> {{ m.prname }} </div>
                            <div> 
                                <div>{{ m.player_id }}</div>
                                <img style="width:1.5em;" :src="m.primage"/>
                            </div>
                        </td>
                        <td style="font-size:0.7em;"><DateTimeDisplay :datetime="new Date(m.sent_time)" /></td>
                        <td style="font-size:0.7em;">
                            <DateTimeDisplay v-if="m.read_time" :datetime="new Date(m.read_time)" />
                            <div v-else> ❌ </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>

<script> 
    const { APIPORT,WEBPROTOCOL,EDGELOCATIONS } = require('../assets/js/enviroment.js');
    import DateTimeDisplay from "@/components/admin/DateTimeDisplay.vue";
    //import UserAgent from "@/components/admin/UserAgent.vue";
    import NavigationMenu from "@/components/NavigationMenu.vue"
    //const { countryCodeEmoji} = require('country-code-emoji');
    //import DataTable from 'datatables.net-vue3';
    //import DataTablesLib from 'datatables.net';    

    //DataTable.use(DataTablesLib);   

	export default {
		name: 'AdminMessagesList',
        props: [],
        components: {
            DateTimeDisplay,
            //UserAgent,
            NavigationMenu,
            //DataTable
        },
        data: function() {
            return {
                section: 'list', // 'list|info|new'
                cookieSet: false,
                adminRights: "",
                uiState: {
                    loading:false,
                    error: {
                        on:false,
                        type:""
                    }
                },
                filters: {
                    action:  this.$route.query.action || "list",
                    message_id: this.$route.query.mid || 0,
                    locations: {
                            current: EDGELOCATIONS['west'],
                            available: EDGELOCATIONS
                        },    
                },
                messages: {

                },
                read_messages: 0
            }
        },
        computed: {
            validCookie: function() {                
                return this.adminRights.length > 5;
                
            },
            isVertical: function() {
                return window.innerHeight > window.innerWidth;
            },
        },
        methods: {
            //countryCodeEmoji,
            getAuthHeader: function() {
                const token = this.adminRights;
                return {
                    headers: { Authorization: `Bearer ${token}` }
                };
            },
            getMessages: function() {
                let action = this.filters.action.length>3 ? this.filters.action : 'messages-list';
                
                let data = {
                    action: "messages-"+action,
                    message_id: this.filters.message_id
                }
                this.axios.post(`${WEBPROTOCOL}${this.filters.locations.current}:${APIPORT}/admin/messages/get/`,data,this.getAuthHeader()).then((response) => {
                        this.messages = response.data;                         
                        this.uiState.loading = false;
                        if (action=='delivery') {
                            this.read_messages = this.messages.filter(message => message.read_time).length;
                            this.messages.sort((a, b) => new Date(b.read_time) - new Date(a.read_time));
                        }                        

                    }).catch((error) => {
                        this.uiState.loading = false;
                        console.log(error);
                        }
                    );
            },
        },
        mounted: function() {
            this.$nextTick(function () {
                const adminCookie = localStorage.getItem("adminAuthToken");
                if (adminCookie && adminCookie.length > 5) {
                    this.cookieSet = true;
                    this.adminRights = adminCookie;
                    }
                this.getMessages();

            });
            //this.filters = localStorage.getItem("adminAuthToken");
        },
        unmounted: function() {
            //localStorage.setItem("adminAuthToken", this.filters);                  
        },
        watch: {
            '$route.query': {
            handler(newQuery,oldQuery) {                
                console.log("nq",newQuery);                    
                console.log("oq",oldQuery);
                if (oldQuery && newQuery!=oldQuery) {
                    window.location.reload();
                }

            },
            immediate: true
            },
            'filters.action': {
            handler(newAction) {                
                this.messages = null;
                if (newAction=='list') {
                    this.$router.push("/messages/list");
                }
            },
            immediate: true
            },
            
        },

    }
</script>

<style lang="scss">

table {
            width: 100%;
            background:darken($c4,5%);
            border-radius: 0.5em;
            text-align: center;
        }

        thead {
            padding:0.2em;
            td {
                border-bottom: 1px solid black;
                padding: 0.3em;
                margin: 0.3em;
                background: none;
            }
        }
        td {
        }
        tbody tr:nth-child(even) {
            background:darken($c4,10%);
        }        

        .message {
        background:red;
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:1em;
    }

    .message_ok {
        background:rgb(81, 101, 15);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:1em;
    }

    .container2 {

        background:rgb(162, 195, 40);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
        display: flex;
        justify-content: space-around;
    }

    .container {
        background:rgb(147, 174, 50);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
    }

    .buttons {
        background:rgb(174, 162, 50);
        padding:0.5em;
        border-radius: 0.5em;
        margin-top:0.5em;
        display: flex;
        justify-content: flex-end;
    }

</style>